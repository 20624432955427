<template>
  <div class="dropdown w-100 w-md-auto" :class="{ show: isDropdownOpen }">
    <button
      class="btn btn-secondary dropdown-toggle w-100 w-md-auto"
      type="button"
      :id="dropdownId"
      :aria-expanded="isDropdownOpen ? 'true' : 'false'"
      @click="toggleDropdown"
    >
      {{ selectedOption.value }}
    </button>
    <ul
      class="dropdown-menu"
      :class="{ show: isDropdownOpen }"
      :aria-labelledby="dropdownId"
    >
      <li
        v-for="option in options"
        :key="option.key"
        @click="selectOption(option)"
      >
        <a class="dropdown-item" href="#">{{ option.value }}</a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, watch } from "vue";

const props = defineProps({
  options: {
    type: Array as () => Array<{ key: string; value: string }>,
    required: true,
  },
  defaultOption: {
    type: Object as () => { key: string; value: string },
    required: true,
  },
  dropdownId: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["selectOption"]);

// Реактивные переменные для хранения текущего состояния dropdown
const selectedOption = ref(props.defaultOption); // Инициализируем текущий выбор
const isDropdownOpen = ref(false);

// Слушаем изменения defaultOption, чтобы обновить selectedOption
watch(
  () => props.defaultOption,
  (newDefaultOption) => {
    selectedOption.value = newDefaultOption; // Обновляем текущее значение
  },
  { immediate: true } // Запускаем сразу при инициализации
);

// Функция для сворачивания/разворачивания dropdown
const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

// Функция для выбора опции из меню
const selectOption = (option: { key: string; value: string }) => {
  selectedOption.value = option; // Обновляем выбранное значение
  isDropdownOpen.value = false; // Закрываем меню
  emits("selectOption", option.key); // Эмитим ключ выбранной опции
};

// Закрываем меню при клике вне компонента
document.addEventListener("click", (event) => {
  const targetElement = event.target as HTMLElement;
  if (!targetElement.closest(`#${props.dropdownId}`)) {
    isDropdownOpen.value = false;
  }
});
</script>
<style scoped>
.w-100 {
  width: 100%;
}

.w-md-auto {
  width: auto !important;
}

/* Стили для показа/скрытия меню */
.show {
  display: block !important;
}

.dropdown-menu {
  display: none;
  min-width: 250px; /* Устанавливаем минимальную ширину */
  max-width: 400px; /* Максимальная ширина меню */
  white-space: normal; /* Позволяем перенос строк */
  word-wrap: break-word; /* Переносим длинные слова */
  padding: 0.5em; /* Пространство внутри меню */
}

.dropdown-toggle {
  white-space: normal; /* Позволяем перенос строк */
  word-wrap: break-word; /* Переносим длинные слова */
  overflow: hidden; /* Скрываем текст, выходящий за пределы кнопки */
  padding: 0.5em 1em; /* Пространство внутри кнопки */
}

.dropdown-item {
  white-space: normal; /* Позволяем перенос строк */
  word-wrap: break-word; /* Переносим длинные слова */
  padding: 0.5em 1em; /* Пространство внутри элемента */
}

/* Ограничиваем ширину кнопки */
.dropdown {
  max-width: 100%; /* Ограничиваем ширину до 100% контейнера */
}
</style>
