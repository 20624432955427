<template>
  <div class="accordion mb-4 pr-0 w-100" id="purchaseList">
    <div class="accordion-item" v-for="(cart, index) in carts" :key="cart.id">
      <h2 class="accordion-header" :id="`heading${cart.id}`">
        <button
          class="accordion-button mr-3"
          :class="{
            collapsed: !cart.show,
          }"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="`#collapse${cart.id}`"
          :aria-expanded="cart.show"
          :aria-controls="`collapse${cart.id}`"
          @click="showCart(index)"
        >
          <div class="col-1">
            Выкуп №{{ cart.id }}
            <template
              v-if="
                [
                  'CREATED',
                  'WAIT_MONEY',
                  'ERROR',
                  'NOTPAYMENT',
                  'CHECKPAYMENT',
                  'PAYMENT',
                  'DELIVERY',
                  'DELIVERED',
                ].includes(cart.status)
              "
            >
              <br />
              <span
                :class="
                  cart.status === 'ERROR'
                    ? statusClassStatus(cart.status).class
                    : ''
                "
              >
                {{ statusClassStatus(cart.status).text }}
              </span>
            </template>
            <template v-if="cart.status === 'ERROR' || cart.error_msg">
              <p class="text-danger small">{{ cart.error_msg }}</p>
            </template>
          </div>
          <template v-if="!cart.show">
            <swiper
              :modules="[Virtual]"
              :slides-per-view="3"
              :space-between="2"
              virtual
              style="width: 14rem"
            >
              <!-- Slides -->
              <swiper-slide
                class="swiper-slide"
                v-for="(item, index) in cart.items"
                :key="index"
                :virtualIndex="index"
              >
                <!-- {{ index }} -->
                <ImageContainer
                  :photo_url="item.product.photo_url"
                ></ImageContainer>
              </swiper-slide>
            </swiper>
          </template>
          <div class="button-group d-flex justify-content-end align-items-end">
            <template
              v-if="
                ['CREATED', 'WAIT_MONEY'].includes(cart.status) && !cart.qrcode
              "
            >
              <div class="d-flex justify-content-end">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Загрузка...</span>
                </div>
              </div>
            </template>
            <template v-else-if="cart.status == 'CHECKPAYMENT'">
              <div>Проверка оплаты...</div>
            </template>
            <template
              v-else-if="
                (cart.qrcode && 'WAIT_MONEY' == cart.status) ||
                (cart.items.some((element) => element.status === 'DELIVERED') &&
                  router.path == '/delivery')
              "
            >
              <MyButton
                @click.stop="openQr(cart)"
                :class="
                  (cart.qrcode?.ttl &&
                    !['DELIVERED', 'DELIVERY'].includes(cart.status) &&
                    cart.qr_show) ||
                  ['DELIVERED', 'DELIVERY'].includes(cart.status)
                    ? 'btn-primary'
                    : 'btn-warning text-white'
                "
                >QrCode
                <MyTimer
                  v-if="
                    cart.qrcode?.ttl &&
                    !['DELIVERED', 'DELIVERY'].includes(cart.status)
                  "
                  :initialTime="cart.qrcode.ttl"
                  @timer-finished="handleTimerFinished(cart)"
                />
              </MyButton>
            </template>
            <template v-else-if="['NOTPAYMENT'].includes(cart.status)">
              <div class="row">
                <MyButton
                  @click.stop="CheckPayment(cart)"
                  class="col me-2 text-nowrap"
                  >Проверка оплаты</MyButton
                >
                <MyButton @click.stop="Payment(cart)" class="col mr-2"
                  >QrCode</MyButton
                >
              </div>
            </template>
            <template v-else-if="['NEW'].includes(cart.status)">
              <MyButton @click.stop="Payment(cart)">QrCode</MyButton>
            </template>
          </div>
        </button>
      </h2>
      <div
        :id="`collapse${cart.id}`"
        class="accordion-collapse collapse"
        :class="{
          show: cart.show,
        }"
        :aria-labelledby="`heading${cart.id}`"
        data-bs-parent="#purchaseList"
      >
        <div class="accordion-body">
          <purchase-item
            :cart="cart"
            :manage="manage"
            @open-status="emiStatus"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from "vue";
import PurchaseItem from "./PurchaseItem.vue";
import { IRepaymentCart } from "../intefaces";
import ImageContainer from "@/components/UI/ImageContainer.vue";
import MyButton from "@/components/UI/MyButton.vue";
import MyTimer from "@/components/MyTimer.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Virtual } from "swiper/modules";
import { useRoute } from "vue-router";
import { statusClassStatus } from "@/hooks/";

const router = useRoute();
const props = defineProps<{
  carts: Array<IRepaymentCart>;
  manage: boolean;
}>();
const emit = defineEmits([
  "open-status",
  "payment",
  "open-qr",
  "timer-finish",
  "check-payment",
]);
// Замените на реальные данные
function showCart(index: number) {
  if (props.carts) {
    // eslint-disable-next-line vue/no-mutating-props
    props.carts[index].show = !props.carts[index].show;
  }
}
function Payment(cart: IRepaymentCart) {
  emit("payment", cart);
}
function openQr(cart: IRepaymentCart) {
  cart.qr_show = true;
  emit("open-qr", cart);
}
function CheckPayment(cart: IRepaymentCart) {
  emit("check-payment", cart);
}
const emiStatus = (id: number) => {
  emit("open-status", id);
};
const handleTimerFinished = (cart: IRepaymentCart) => {
  emit("timer-finish", cart);
};
</script>
<style scoped>
.button-group {
  margin-left: auto;
  margin-right: 10px;
}

.accordion-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.accordion-button .d-flex {
  flex-grow: 1;
}

@media (max-width: 576px) {
  .accordion-button .d-flex {
    flex-direction: column;
    align-items: flex-start;
  }
  .button-group {
    width: 100%;
    justify-content: flex-end;
    margin-right: 10px;
  }
}
</style>
