<template>
  <VueFinalModal
    class="modal-dialog-centered"
    content-class="flex flex-col mx-auto my-1 max-w-100 col-sm-3 p-3 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-4 rounded"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="replenishBalanceModalLabel">
          Пополнить баланс
        </h5>
        <CloseButton @click="emit('closeicon')"></CloseButton>
      </div>
      <div class="modal-body">
        <!-- <p>С баланса списывается ТОЛЬКО стоимость услуг за сервис</p> -->
        <div class="alert alert-success" role="alert">
          С баланса списывается стоимость услуг сервиса, товар оплачивается в
          разделе "Выкупы" ОТДЕЛЬНО
        </div>
        <div class="alert alert-danger text-wrap" role="alert">
          Баланс предназначен для оплаты услуг сервиса.
        </div>
        <div class="col me-2">
          <h5>Введите сумму для пополнения:</h5>
          <div class="row">
            <NumberInput
              :max="1000000"
              :step="100"
              v-model="amount"
              :style="{ width: '95%' }"
            />
            <h5 class="col p-0">₽</h5>
          </div>
          <MyButton @click="emitPayment()" class="mt-2 w-100 mx-1"
            >Оплатить</MyButton
          >
          <MyButton
            v-if="PaymentLink"
            class="mt-2 w-100 mx-1"
            @click="OpenPaymentPage()"
            >Перейти к оплате</MyButton
          >
        </div>
        <hr />
        <div class="btn-group row" role="group" aria-label="Basic example">
          <TelegramButton :href="`https://t.me/+4j1dwdCK7DhmNDRi`" class="mb-2">
            Перейти в нашу группу Telegram
          </TelegramButton>
          <TelegramButton :href="`https://t.me/m/PjoemevNZWZi`">
            Написать менеджеру в Telegram
          </TelegramButton>
        </div>
        <!-- <div>
          <h6>Сумма к пополнению</h6>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-outline-primary">1000₽</button>
            <button type="button" class="btn btn-outline-primary">1500₽</button>
            <button type="button" class="btn btn-outline-primary">3000₽</button>
            <button type="button" class="btn btn-outline-primary">5000₽</button>
          </div>
        </div>
        <div class="mb-3">
          <label for="email" class="form-label">Электронная почта*</label>
          <input
            type="email"
            class="form-control"
            id="email"
            placeholder="name@example.com"
          />
        </div> -->
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Отмена
        </button>
        <button type="button" class="btn btn-primary">Пополнить</button>
      </div> -->
    </div>
  </VueFinalModal>
</template>
<script setup lang="ts">
import { defineEmits, ref } from "vue";
import { VueFinalModal } from "vue-final-modal";
import TelegramButton from "@/components/UI/TelegramButton.vue";
import CloseButton from "@/components/UI/CloseButton.vue";
import NumberInput from "@/components/UI/NumberInput.vue";
import MyButton from "@/components/UI/MyButton.vue";
import PaymentService from "../hooks/PaymentService";
import { notify } from "@kyvg/vue3-notification";

const amount = ref(0);
const PaymentLink = ref("");
const emit = defineEmits<{
  (e: "closeicon"): void;
  (e: "update", amount: number): void;
}>();
const emitPayment = async () => {
  if (amount.value > 0) {
    PaymentLink.value = await PaymentService.getPaymentLink(amount.value);
  } else {
    notify({
      text: "Сумма должана быть больше нуля",
    });
  }
};
const OpenPaymentPage = () => {
  window.open(PaymentLink.value, "_blank", "noopener,noreferrer");
};
</script>
