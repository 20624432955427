<template>
  <div class="input-wrapper">
    <input
      type="text"
      class="form-control"
      :id="id"
      :placeholder="placeholder"
      :value="modelValue"
      @input="updateInput"
    />
    <!-- Слот для спиннера -->
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from "vue";

// Определение пропсов с использованием TypeScript для типизации
defineProps<{
  modelValue?: string | number;
  placeholder?: string;
  id: string;
}>();

// Определение функции emit для эмитации событий
const emit = defineEmits<{
  (event: "update:modelValue", value: string | number): void;
}>();

// Функция обновления входного значения и эмитирования изменения
function updateInput(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  emit("update:modelValue", inputElement.value);
}
</script>

<style scoped>
.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

input {
  width: 100%;
  padding-right: 2.5rem; /* Отступ для спиннера */
}

::v-deep(.spinner-border) {
  position: absolute;
  right: 0.1rem;
  /* top: 50%; */
  /* transform: translateY(-50%); */
  pointer-events: none; /* Спиннер не будет мешать вводу текста */
}
</style>
