import { ref } from "vue";
import { IService } from "./interfaces";
import axios from "@/axiosInstance";
import { uuid } from "vue-uuid";

export const getId = () => {
  return uuid.v1();
};

export const getService = async (name_service: string) => {
  const service = ref<IService>();
  const response = await axios.get("/payment/get/service", {
    params: {
      name: name_service,
    },
  });
  service.value = response.data;
  return service;
};

export async function sleep(seconds: number) {
  const milliseconds = seconds * 1000;
  await new Promise((resolve) => setTimeout(resolve, milliseconds));
}

// Функция для получения текущей даты и даты через две недели в формате YYYY-MM-DDTHH:MM
export function getFormattedDate(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

interface StatusInfo {
  class: string;
  text: string;
}

// Объект для хранения соответствий статусов
const statusMap: Record<string, StatusInfo> = {
  // review statuses
  ONREVIEW: { class: "text-warning", text: "На проверке" },
  PUBLISH: { class: "text-success", text: "Опубликован" },
  EXCLUDED: { class: "text-danger", text: "Исключен" },

  // transaction statuses
  REFUND: { class: "text-warning", text: "Возврат" },
  ADDITION: { class: "text-success", text: "Пополнение" },
  DEBITING: { class: "text-primary", text: "Списание" },

  // repayment statuses
  ACTIVE: { class: "text-success", text: "Активен" },
  DONE: { class: "text-primary", text: "Завершен" },

  // repayment item statuses
  NEW: { class: "text-success", text: "Новый" },
  WAIT_MONEY: { class: "text-warning", text: "Ожидание оплаты" },
  NOTPAYMENT: { class: "text-danger", text: "Не оплачен" },
  CHECKPAYMENT: { class: "text-warning", text: "Проверка оплаты" },
  DELIVERED: { class: "text-primary", text: "Готов к получению" },
  REJECT: { class: "text-danger", text: "Отказ" },
  DELIVERY: { class: "text-primary", text: "В доставке" },
  PAYMENT: { class: "text-primary", text: "Карта привязана" },
  ORDER: { class: "text-primary", text: "В процессе выкупа" },
  RECIEVE: { class: "text-primary", text: "Получен" },

  // universe statuses
  CREATED: { class: "text-primary", text: "Создан" },
  PROCCESS: { class: "text-warning", text: "В процессе" },
  ERROR: { class: "text-danger", text: "Ошибка" },
};

// Функция для получения класса и текста статуса
export function statusClassStatus(status: string): StatusInfo {
  const statusInfo = statusMap[status];
  if (statusInfo) {
    return statusInfo;
  }
  // Возвращаем значение по умолчанию, если статус не найден
  return { class: "text-primary", text: "Неизвестный статус" };
}
