<template>
  <div class="container-fluid pr-0">
    <div class="block">
      <!-- Сайдбар -->
      <!-- <div class="col-md-auto">
        <MenuPanel />
      </div> -->
      <!-- Основной контент с динамическим смещением -->
      <div
        :class="
          isLoggedIn ? ['main-content', { 'is-expanded': !isCollapsed }] : ''
        "
      >
        <slot></slot>
        <!-- Слот для вставки страниц -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
// import MenuPanel from "@/components/MenuPanel.vue";
// Предположим, что sidebarState является реактивным источником данных и доступен для импорта.
import { useStore } from "vuex";

const store = useStore();
const isCollapsed = computed(() => store.state.isCollapse);
const isLoggedIn = computed(() => store.state.loginState);
</script>

<style scoped>
.main-content {
  transition: margin-left 0.3s ease;
  margin-left: 80px;
  max-width: 80%;
  margin-right: 5px;
}
@media (max-width: 767.98px) {
  /* Для экранов меньше размера md */
  .main-content {
    margin-left: 25px !important; /* Убираем смещение основного контента */
    margin-right: 5px;
  }
  .block {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .container-fluid {
    padding: 0;
    margin: 0;
  }
}
.is-expanded {
  margin-left: 250px; /* Увеличьте значение в зависимости от вашей нужды */
  max-width: 80%;
}
</style>
