<template>
  <LayuotPage>
    <!-- Контейнер со снятым паддингом для xs и большим слева на md+ -->
    <div class="container-sm p-0 ps-md-7">
      <!-- Заголовок -->
      <div class="row">
        <div class="col-12">
          <h2 class="pt-3 pb-1 text-left font-bold font-up deep-purple-text">
            Вопросы на товар
          </h2>
        </div>
      </div>

      <!-- Хлебные крошки и кнопка "Видеоинструкция" -->
      <div class="row g-3 align-items-center mb-3">
        <div class="col-12 col-md-8">
          <MyBreadCrumbs :crumbs="crumbs" class="mt-1" />
        </div>
        <div class="col-12 col-md-4 text-md-end">
          <MyButton @click="VideoOpen" class="btn-success">
            Видеоинструкция
          </MyButton>
        </div>
      </div>

      <!-- Форма ввода данных для вопроса -->
      <div class="row">
        <!-- Артикул -->
        <div class="col-md-4 mb-1">
          <label for="article" class="form-label">Артикул</label>
          <TextInput
            v-model="newQuestion.article"
            id="article"
            placeholder="Артикул"
          />
        </div>

        <!-- Дата и время публикации -->
        <div class="col-md-4 mb-1">
          <label for="publish_date" class="form-label">
            Дата и время публикации вопроса
          </label>
          <div class="input-group">
            <input
              type="datetime-local"
              class="form-control"
              id="publish_date"
              v-model="newQuestion.publish_date"
              :min="minDate"
              :max="maxDate"
            />
          </div>
        </div>

        <!-- Пол -->
        <div class="col-md-4 mb-1">
          <label for="gender" class="form-label">Пол</label>
          <div class="input-group">
            <MySelect
              id="gender"
              v-model="newQuestion.gender"
              :options="gender"
              disable
            />
          </div>
        </div>
      </div>

      <div class="row">
        <!-- Вопрос -->
        <div class="col-md-8 mb-1">
          <label for="question" class="form-label">Ваш вопрос</label>
          <div class="input-group">
            <input
              v-model="newQuestion.question"
              id="question"
              placeholder="Ваш вопрос"
              class="form-control"
            />
          </div>
        </div>
        <!-- Стоимость -->
        <div class="col-md-3 mb-1">
          <label class="form-label d-block">Стоимость:</label>
          <div>{{ service?.cost }} Р/шт</div>
        </div>
      </div>

      <!-- Кнопка "Добавить" -->
      <div class="row">
        <div class="col-12 mb-1">
          <MyButton
            type="submit"
            class="btn btn-primary"
            @click="SendQuestion"
            :disabled="createLoading"
          >
            <span
              v-if="createLoading"
              class="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
            {{ createLoading ? "Отправка..." : "Добавить" }}
          </MyButton>
        </div>
      </div>

      <!-- Таблица вопросов -->
      <div class="row">
        <div class="col-12">
          <!-- Горизонтальная прокрутка на мобильных -->
          <div class="table-responsive">
            <table class="table table-striped">
              <!-- Заголовок таблицы -->
              <thead>
                <tr>
                  <th>#</th>
                  <th>Товар</th>
                  <th>Вопрос</th>
                  <th>Пол</th>
                  <th>Стоимость</th>
                  <th>Статус</th>
                  <th>Дата создания</th>
                  <th>Дата окончания</th>
                  <th></th>
                </tr>
              </thead>
              <!-- Тело таблицы -->
              <tbody>
                <tr v-for="(question, index) in items" :key="index">
                  <td class="col">{{ index + 1 }}</td>
                  <td class="col-1">
                    <a
                      :href="`https://www.wildberries.ru/catalog/${question.product.article}/detail.aspx`"
                    >
                      <ImageContainer :photo_url="question.product.photo_url" />
                    </a>
                  </td>
                  <td class="col-7">{{ question.question }}</td>
                  <td class="col-1">{{ question.gender }}</td>
                  <td class="col-1" v-if="service">{{ service.cost }} руб.</td>
                  <td class="col-4 col-md-7">
                    <template v-if="question.status">
                      <StatusSpan
                        :color-class="statusClassStatus('DONE').class"
                      >
                        {{ statusClassStatus("DONE").text }}
                      </StatusSpan>
                    </template>
                    <template v-else>
                      <StatusSpan
                        :color-class="statusClassStatus('PROCCESS').class"
                      >
                        {{ statusClassStatus("PROCCESS").text }}
                      </StatusSpan>
                    </template>
                  </td>
                  <td class="col-3">{{ question.created }}</td>
                  <td class="col-3">{{ question.publish_date }}</td>
                </tr>
              </tbody>
              <!-- Скелетоны при загрузке (если нет записей) -->
              <template v-if="items.length === 0 && isLoading">
                <RowSkeleton v-for="index in 5" :key="index" :columns="9" />
              </template>
            </table>
          </div>
          <!-- Триггер для подгрузки -->
          <div ref="loadMoreTrigger" class="load-more-trigger"></div>
        </div>
      </div>
    </div>
    <ModalsContainer />
  </LayuotPage>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import LayuotPage from "../LayuotPage.vue";
import MyButton from "@/components/UI/MyButton.vue";
import MySelect from "@/components/UI/MySelect.vue";
import TextInput from "@/components/UI/TextInput.vue";
import MyBreadCrumbs from "@/components/MyBreadCrumbs.vue";
import ImageContainer from "@/components/UI/ImageContainer.vue";
import RowSkeleton from "@/components/skeletons/RowSkeleton.vue";
import QuestionService from "./hooks/QuestionService";
import { IService } from "@/hooks/interfaces";
import { getService, getFormattedDate, statusClassStatus } from "@/hooks";
import StatusSpan from "@/components/UI/StatusSpan.vue";
import { notify } from "@kyvg/vue3-notification";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";
import { useIntersectionObserver } from "@/hooks/useIntersectionObserver";
import VideoModal from "@/components/VideoModal.vue";
import { ModalsContainer, useModal } from "vue-final-modal";

const crumbs = [
  {
    text: "Вопросы на товар",
    url: "/questions",
  },
  {
    text: "Вопросы",
  },
];
const gender = [
  { name: "Муж.", value: "male" },
  { name: "Жен.", value: "female" },
];

const service = ref<IService>();
const createLoading = ref(false);
onMounted(async () => {
  service.value = (await getService("question")).value;
});
const newQuestion = ref({
  article: "",
  question: "",
  gender: "",
  publish_date: "",
});
function validateForm() {
  if (!newQuestion.value.question) {
    notify({
      text: "Напишите ваш вопрос",
    });
    return false;
  }
  if (newQuestion.value.publish_date) {
    const publishDate = new Date(newQuestion.value.publish_date);
    if (publishDate < currentDate) {
      notify({
        text: "Дата публикации не должна быть меньше текущей даты",
      });
      return false;
    }
  } else {
    newQuestion.value.publish_date = getFormattedDate(currentDate);
  }

  return true;
}
const SendQuestion = async () => {
  createLoading.value = true;
  const is_validate = validateForm();
  if (is_validate) {
    const res = await QuestionService.createQuestion(newQuestion);
    if (res) {
      unobserve();
      await refresh();
      observe();
    }
  }
  createLoading.value = false;
};
const currentDate = new Date();
currentDate.setSeconds(0, 0);
const twoWeeksFromNow = new Date(
  currentDate.getTime() + 14 * 24 * 60 * 60 * 1000
);

const minDate = ref(getFormattedDate(currentDate));
const maxDate = ref(getFormattedDate(twoWeeksFromNow));
const fetchData = (page: number, pageSize: number) =>
  QuestionService.getQuestions(page, pageSize);

// Используем useInfiniteScroll с fetchData
const { items, loadMore, refresh, isLoading } = useInfiniteScroll(fetchData);
// Подключаем IntersectionObserver, передавая loadMore как callback
const {
  target: loadMoreTrigger,
  observe,
  unobserve,
} = useIntersectionObserver(loadMore, { rootMargin: "100px" });

const { open: VideoOpen, close: VideoClose } = useModal({
  component: VideoModal,
  attrs: {
    videotoken: "ec7bd07ab0e79e26a6699e6e3f261b3f",
    onCloseicon() {
      VideoClose();
    },
  },
});
</script>
