<template>
  <LayuotPage>
    <div class="container-sm ps-7">
      <div class="card-body">
        <div class="row pt-4">
          <div class="col-6">
            <h1>Группа выкупов {{ $route.params.id }}</h1>
          </div>
          <div class="col-4 m-auto d-flex justify-content-end">
            <MyButton @click="$router.back()"> Закрыть </MyButton>
          </div>
        </div>
        <div class="row">
          <MyBreadCrumbs :crumbs="crumbs"></MyBreadCrumbs>
        </div>
        <div v-if="!isLoading">
          <PurchaseList
            :carts="repayment"
            :manage="manage"
            @payment="Payment"
            @open-qr="getQR"
            @timer-finish="checkStatus"
            @check-payment="runCheckPayment"
          ></PurchaseList>
        </div>
        <div class="row" v-else>
          <AccordionSkeleton></AccordionSkeleton>
        </div>
      </div>
    </div>
    <ModalsContainer />
  </LayuotPage>
</template>
<script setup lang="ts">
import LayuotPage from "../LayuotPage.vue";
import MyButton from "@/components/UI/MyButton.vue";
import MyBreadCrumbs from "@/components/MyBreadCrumbs.vue";
import PurchaseList from "./components/PurchaseList.vue";
import QRModal from "./modals/QRModal.vue";
import RepaymentService from "./hooks/RepaymentService";
import { ModalsContainer, useModal } from "vue-final-modal";
import { IRepaymentCart } from "./intefaces";
import { useRoute } from "vue-router";
// import ToolTip from "@/components/ToolTip.vue";
import { ref, watch } from "vue";
import AccordionSkeleton from "@/components/skeletons/AccordionSkeleton.vue";
import { sleep } from "@/hooks";
import store from "@/store";

const route = useRoute();
const crumbs = [
  {
    text: "Группы выкупов",
    url: "/repayment",
  },
  {
    text: "Выкупы",
  },
  {
    text: "Группа выкупов " + route.params.id,
  },
];
const manage = false;
const repaymentId = ref(route.params.id);
const { isLoading, repayment } = RepaymentService.getRepayment(
  repaymentId.value
);
const initializeRepayment = () => {
  repayment.value.forEach((cart: IRepaymentCart) => {
    if (
      ["CREATED", "WAIT_MONEY", "CHECKPAYMENT", "PAYMENT"].includes(cart.status)
    ) {
      checkStatus(cart);
    }
  });
};
watch(repayment, (newVal) => {
  if (!isLoading.value && newVal.length > 0) {
    initializeRepayment();
  }
});
const cartQr = ref(
  // "https://qr.nspk.ru/AD1P00098AILAT6Q80CPLJBQDKU1AI2O?type=02&bank=100000000111&sum=141600&cur=RUB&crc=846A"
  ""
);
const getQrCode = async (cart: IRepaymentCart) => {
  let result;
  try {
    const qrcode = await RepaymentService.getQr(cart.id);
    if (qrcode.value) {
      cart.loading = false;
      cart.qrcode = qrcode.value;
      return;
    } else {
      cart.qrcode = undefined;
      cart.loading = true;
    }
    // else {
    // await sleep(7);
    // retries--;
    // }
  } catch (error: any) {
    if (error.name === "AbortError") {
      console.log("Request was aborted");
      return;
    }
    console.error("Error fetching task result:", error);
  }
  // if (!result) {
  //   cart.status = "ERROR";
  // }
  // }
  // if (retries == 0 && !result) {
  //   // RepaymentService.setStatus(cart.id, "fail");
  //   cart.status = "CREATED";
  //   cart.loading = false;
  // }
};
const Payment = async (cart: IRepaymentCart) => {
  cart.loading = true;
  cart.status = "CREATED";
  const { token } = await RepaymentService.runRepayment(cart.id);
  if (token.value) {
    sleep(5);
    cart.status = "CREATED";
    checkStatus(cart);
  }
  if (!token) {
    cart.loading = false;
    cart.status = "NEW";
    // Если не удалось получить токен, завершаем выполнение
    return;
  }
};
const runCheckPayment = async (cart: IRepaymentCart) => {
  const { token } = await RepaymentService.runCheckPayment(cart.id);
  if (token.value) {
    cart.status = "CHECKPAYMENT";
    checkStatus(cart);
  }
};
const checkStatus = async (cart: IRepaymentCart) => {
  let retries = 50;
  while (retries) {
    await sleep(5);
    const { status, error_msg } = await RepaymentService.checkStatus(cart.id);
    cart.status = status.value;
    cart.error_msg = error_msg.value;
    if (cart.status === "WAIT_MONEY") {
      await getQrCode(cart);
    }
    if (cart.status === "PAYMENT") {
      cart.qrcode = undefined;
    }
    if (
      !["CREATED", "WAIT_MONEY", "CHECKPAYMENT", "PAYMENT"].includes(
        cart.status
      )
    ) {
      store.dispatch("refreshBalance");
      return;
    }
  }
};
const getQR = async (cart: IRepaymentCart) => {
  if (cart.qrcode) {
    cartQr.value = cart.qrcode.qr_url; // Обновляем значение cartQr реактивной ссылки
    console.log(cartQr.value);
    open(); // Открываем QR-код
  }
};
const { open, close } = useModal({
  component: QRModal,
  attrs: {
    qrcode: cartQr,
    onCloseicon() {
      close();
    },
  },
});
</script>
