<template>
  <LayuotPage>
    <div class="container-sm p-0 ps-md-7">
      <!-- Заголовок -->
      <div class="row">
        <div class="col-12">
          <h2 class="pt-3 pb-1 text-left font-bold font-up deep-purple-text">
            Лайки на бренд или товар
          </h2>
        </div>
      </div>

      <!-- Хлебные крошки и Видеоинструкция -->
      <div class="row g-3 align-items-center mb-3">
        <div class="col-12 col-md-8">
          <MyBreadCrumbs :crumbs="crumbs" class="mt-1" />
        </div>
        <div class="col-12 col-md-4 text-md-end">
          <MyButton @click="VideoOpen" class="btn-success">
            Видеоинструкция
          </MyButton>
        </div>
      </div>

      <!-- Форма для добавления лайков -->
      <div class="row g-3 mb-1 align-items-center">
        <!-- Ссылка на товар или бренд -->
        <div class="col-sm-6 col-md-3">
          <label for="url" class="visually-hidden">
            Ссылка на товар или бренд
          </label>
          <TextInput
            v-model="likeForm.url"
            :placeholder="'Ссылка на товар или бренд'"
            :id="'url'"
          />
        </div>
        <!-- Количество -->
        <div class="col-sm-6 col-md-2 mb-1">
          <NumberInput v-model="likeForm.count" :step="1" :max="100" />
        </div>
        <!-- Часы -->
        <div class="col-sm-6 col-md-2 mb-1">
          <MySelect :options="hours" v-model="likeForm.hours" />
        </div>
        <!-- Стоимость -->
        <div class="col-sm-6 col-md-2 mb-1">
          Стоимость:<br />
          {{ service?.cost }} Р/шт
        </div>
        <!-- Кнопка Добавить (прижата к правому краю) -->
        <div class="col-sm-6 col-md-3 d-flex justify-content-end">
          <MyButton
            type="submit"
            class="btn btn-primary"
            @click="SendLikes"
            :disabled="createLoading"
          >
            <span
              v-if="createLoading"
              class="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
            {{ createLoading ? "Отправка..." : "Добавить" }}
          </MyButton>
        </div>
      </div>

      <!-- Таблица с результатами (горизонтальная прокрутка) -->
      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-striped">
              <!-- Заголовок таблицы -->
              <thead>
                <tr>
                  <th>#</th>
                  <th>Товар</th>
                  <th>Тип</th>
                  <th>Прогресс</th>
                  <th>Стоимость</th>
                  <th>Статус</th>
                  <th>Дата создания</th>
                  <th>Дата окончания</th>
                  <th></th>
                </tr>
              </thead>

              <!-- Тело таблицы -->
              <tbody>
                <tr v-for="(order, index) in items" :key="index">
                  <td class="col">{{ index + 1 }}</td>
                  <td class="col-1">
                    <ImageContainer :photo_url="order.product.photo_url" />
                  </td>
                  <td class="col-3">{{ order.type }}</td>
                  <td class="col-3">{{ order.done }}/{{ order.total }}</td>
                  <td v-if="service" class="col-3">
                    {{ service.cost * order.total }} руб.
                  </td>
                  <td class="col-3">
                    <template v-if="order.done === order.total">
                      <StatusSpan
                        :color-class="statusClassStatus('DONE').class"
                      >
                        {{ statusClassStatus("DONE").text }}
                      </StatusSpan>
                    </template>
                    <template v-else>
                      <StatusSpan
                        :color-class="statusClassStatus('PROCCESS').class"
                      >
                        {{ statusClassStatus("PROCCESS").text }}
                      </StatusSpan>
                    </template>
                  </td>
                  <td class="col-3">{{ order.date_start }}</td>
                  <td class="col-3">{{ order.date_end }}</td>
                </tr>
              </tbody>

              <!-- Скелетоны при загрузке, если нет записей -->
              <template v-if="items.length === 0 && isLoading">
                <RowSkeleton v-for="index in 5" :key="index" :columns="9" />
              </template>
            </table>
          </div>
          <!-- Триггер для подгрузки (если есть бесконечный скролл) -->
          <div ref="loadMoreTrigger" class="load-more-trigger"></div>
        </div>
      </div>
    </div>
    <ModalsContainer />
  </LayuotPage>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import LayuotPage from "../LayuotPage.vue";
import RowSkeleton from "@/components/skeletons/RowSkeleton.vue";
import MyButton from "@/components/UI/MyButton.vue";
import MySelect from "@/components/UI/MySelect.vue";
import TextInput from "@/components/UI/TextInput.vue";
import MyBreadCrumbs from "@/components/MyBreadCrumbs.vue";
import NumberInput from "@/components/UI/NumberInput.vue";
import ImageContainer from "@/components/UI/ImageContainer.vue";
import LikeService from "./hooks/LikeService";
import { getService } from "@/hooks";
import { IService } from "@/hooks/interfaces";
import { statusClassStatus } from "@/hooks";
import StatusSpan from "@/components/UI/StatusSpan.vue";
import { useIntersectionObserver } from "@/hooks/useIntersectionObserver";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";
import VideoModal from "@/components/VideoModal.vue";
import { ModalsContainer, useModal } from "vue-final-modal";

const likeForm = ref({
  url: "",
  hours: "3",
  count: 10,
});
const crumbs = [
  {
    text: "Лайки",
    url: "/like",
  },
  {
    text: "Лайки на бренд или товар",
  },
];
const hours = [
  { value: "3", name: "3 часа" },
  { value: "12", name: "12 часов" },
  { value: "24", name: "1 день" },
  { value: "72", name: "3 дня" },
  { value: "168", name: "7 дней" },
  { value: "336", name: "14 дней" },
];
const service = ref<IService>();
const createLoading = ref(false);
onMounted(async () => {
  service.value = (await getService("question")).value;
});
const SendLikes = async () => {
  createLoading.value = true;
  const res = await LikeService.createLikes(likeForm);
  if (res) {
    unobserve();
    await refresh();
    observe();
    createLoading.value = false;
  } else {
    createLoading.value = false;
  }
};
const fetchData = (page: number, pageSize: number) =>
  LikeService.getLikes(page, pageSize);

// Используем useInfiniteScroll с fetchData
const { items, loadMore, refresh, isLoading } = useInfiniteScroll(fetchData);
// Подключаем IntersectionObserver, передавая loadMore как callback
const {
  target: loadMoreTrigger,
  observe,
  unobserve,
} = useIntersectionObserver(loadMore, { rootMargin: "100px" });

const { open: VideoOpen, close: VideoClose } = useModal({
  component: VideoModal,
  attrs: {
    videotoken: "4a9830ab9c716ef0c07e58cce6cd521b",
    onCloseicon() {
      VideoClose();
    },
  },
});
</script>
