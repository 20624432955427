<template>
  <VueFinalModal
    class="modal-dialog-centered"
    content-class="flex col mx-auto my-1 max-w-120 col-sm-6 p-3 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-4 rounded"
  >
    <div class="modal-content">
      <div class="modal-header flex justify-end">
        <CloseButton @click="emit('closeicon')"></CloseButton>
      </div>
      <div class="modal-body">
        <div class="ratio ratio-16x9">
          <iframe
            width="720"
            height="405"
            :src="`https://rutube.ru/play/embed/${videotoken}/`"
            frameBorder="0"
            allow="clipboard-write; autoplay"
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>
<script setup lang="ts">
import { defineEmits, defineProps } from "vue";
import CloseButton from "@/components/UI/CloseButton.vue";
import { VueFinalModal } from "vue-final-modal";

defineProps<{
  videotoken: string;
}>();

const emit = defineEmits<{
  (e: "closeicon"): void;
}>();
</script>
<style scoped>
.modal-header {
  display: flex;
  justify-content: flex-end;
}
</style>
