import { ref } from "vue";
import axios from "@/axiosInstance";
import {
  IProfile,
  // IUserLogin,
  IUserRegistration,
} from "@/views/auth/interfaces";
import qs from "qs";

class AuthService {
  async changeProfile(profile_data: any) {
    const response = await axios.put("token/update/user", profile_data);
    if (response.status == 200) {
      return true;
    } else {
      return false;
    }
  }

  async changePassword(password: string) {
    const data: any = {
      new_password: password,
    };

    const response = await axios.post("token/update/password", data);

    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  }
  async recoveryPassword(password: string, token?: string) {
    const data: any = {
      new_password: password,
    };

    if (token) {
      data.token = token;
    }

    const response = await axios.post("token/recovery/password", data);

    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  }

  async signUp(newUser: IUserRegistration) {
    const response = await axios.post("token/signup", newUser);
    if (response.status == 201 || response.status == 200) {
      return true;
    } else {
      return false;
    }
  }

  async getProfile() {
    const profile = ref<IProfile>();
    const response = await axios.get("token/get/profile");
    if (response.status) {
      profile.value = response.data;
    }
    return profile;
  }

  async sendVerifyEmail() {
    try {
      const response = await axios.get("token/get/send/verifyemail");
      if (response.status == 200) {
        return true;
      }
    } catch (err: any) {
      return false;
    }
  }

  async confirmAccount(token: string) {
    const loading = ref(false);
    const error = ref("");
    const message = ref("");

    loading.value = true;
    try {
      const response = await axios.put(`token/put/verify?token=${token}`);
      message.value = response.data.message;
    } catch (err: any) {
      error.value =
        err.response?.data?.message ||
        "Произошла ошибка при подтверждении аккаунта.";
    } finally {
      loading.value = false;
    }
    return { loading: loading, error: error, message: message };
  }

  async forgotPassword(email: string) {
    const loading = ref(false);
    const error = ref("");
    const message = ref("");

    loading.value = true;
    // try {
    const data = {
      email: email,
    };
    const response = await axios.post("token/post/forgot", data);
    // message.value = response.data.message; // предположим, что ответ содержит сообщение
    if (response.status == 200) {
      return true;
    } else {
      return false;
    }
    // } catch (err: any) {
    //   error.value = err.response?.data?.message || "Произошла ошибка";
    // } finally {
    //   loading.value = false;
    // }
    // return { loading: loading, error: error, message: message };
  }
  async getPermissions() {
    const permissions = ref<object>();
    try {
      const response = await axios.get("token/get/permissions");
      permissions.value = response.data;
    } catch (err: any) {
      console.log(err);
    }
    return permissions.value;
  }
}

export default new AuthService();
