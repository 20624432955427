<template>
  <div class="card mb-3 h-100">
    <div class="card-body px-2 d-flex flex-column">
      <div class="row mb-2">
        <i class="bx bx-user br-3 col-1"></i>
        <h5 class="card-title col">{{ review.wbUserDetails.name }}</h5>
      </div>
      <h6 class="card-subtitle mb-2 text-muted">{{ review.createdDate }}</h6>
      <div>
        <span v-for="n in review.productValuation" :key="n" class="text-warning"
          >&#9733;</span
        >
        <span
          v-for="n in 5 - review.productValuation"
          :key="n"
          class="text-secondary"
          >&#9733;</span
        >
      </div>
      <transition name="text-transition">
        <template v-if="isFullText">
          <p class="card-text">
            <template v-if="review.pros">
              <b> Достоинства: </b><br />
              {{ review.pros }}
              <br />
            </template>
            <template v-if="review.cons">
              <b> Недостатки:</b><br />
              {{ review.cons }}
              <br />
            </template>
            <template v-if="review.text">
              <b> Комментарий: </b> <br />
              {{ review.text }}
            </template>
          </p>
        </template>
        <template v-else>
          <p class="card-text">
            <template v-if="review.pros">
              <b> Достоинства </b><br />
              {{ review.pros.slice(0, 20) }}
              <br />
            </template>
            <template v-if="review.cons">
              <b> Недостатки</b><br />
              {{ review.cons.slice(0, 20) }}
              <br />
            </template>
            <template v-if="review.text">
              <b> Комментарий к отзыву </b> <br />
              {{ review.text.slice(0, 20) }}
            </template>
          </p>
        </template>
      </transition>
      <button
        @click="toggleText"
        class="btn btn-sm btn-link mt-0 p-0"
        v-if="review.text.length > 50"
      >
        {{ isFullText ? "Свернуть" : "Ещё..." }}
      </button>
    </div>
    <div class="card-footer d-flex flex-column align-items-center px-3">
      <div class="d-flex align-items-center mb-2">
        <i class="bx bx-like me-1"></i>
        <CustomNumberInput
          v-model="review.pluses"
          :initial-value="review.votes.pluses"
          @update="likeReview"
        />
      </div>
      <div class="d-flex align-items-center">
        <i class="bx bx-dislike me-1"></i>
        <CustomNumberInput
          v-model="review.minuses"
          :initial-value="review.votes.minuses"
          @update="dislikeReview"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from "vue";
import CustomNumberInput from "./CustomNumberInput.vue";
import { Feedback } from "@/hooks/interfaces";

const props = defineProps<{ review: Feedback }>();
const review = ref(props.review);
const isFullText = ref(false);
const emit = defineEmits(["update"]);

const toggleText = () => {
  isFullText.value = !isFullText.value;
};

const likeReview = (newLikes: number) => {
  emit("update", { id: props.review.id, vote: "plus", count: newLikes });
};

const dislikeReview = (newDislikes: number) => {
  emit("update", { id: props.review.id, vote: "minus", count: newDislikes });
};
</script>

<style scoped>
.card-title {
  font-size: 1.25rem;
}

.card-subtitle {
  font-size: 0.875rem;
}

.text-transition {
  transition: max-height 1.5s ease-in-out, opacity 1.5s ease-in-out;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
}

.text-transition-enter-active,
.text-transition-leave-active {
  max-height: 500px;
  opacity: 1;
}

.text-transition-enter-from,
.text-transition-leave-to {
  max-height: 0;
  opacity: 0;
}

.card-footer {
  background-color: white;
}
</style>
