import axios from "@/axiosInstance";
import { ref } from "vue";
import { IReview, IProductReview, ReviewForm } from "../intefaces";
import store from "@/store";

class ReviewService {
  AvaibleReviews = ref(Array<IProductReview>());
  isLoading = ref(true);
  chunkSize = 1 * 1024 * 1024;

  async fetchingAVReviews(avaible: boolean) {
    try {
      const response = await axios.get(`repayment/get/reviews`, {
        params: { av: avaible },
      });
      if (avaible) {
        this.AvaibleReviews.value = response.data.products;
      }
    } catch (e) {
      alert("Error");
    } finally {
      this.isLoading.value = false;
    }
  }

  getAvailableReview(avaible: boolean) {
    // await this.fetchingAVReviews(avaible);
    this.fetchingAVReviews(avaible);
    return { isLoading: this.isLoading, AvaibleReviews: this.AvaibleReviews };
  }

  async getPublishedReviews(page: number, pageSize: number) {
    const Reviews = ref<Array<IReview>>([]);
    try {
      const response = await axios.get(`repayment/get/reviews`, {
        params: { av: false, page: page, size: pageSize },
      });
      Reviews.value = response.data;
      return Reviews.value;
    } catch (e) {
      alert("Error");
    }
  }

  async uploadFilesAndForm(
    reviewForm: ReviewForm,
    photos: File[]
  ): Promise<void> {
    // Загружаем файлы чанками и получаем пути к ним на сервере
    const filePaths = await this.uploadFilesInChunks(photos);
    if (photos.length == filePaths.length) {
      // Отправляем данные формы с путями к файлам
      await this.sendFormData(reviewForm, filePaths);
    }
  }

  // Загрузка файлов чанками и получение путей к ним на сервере
  private async uploadFilesInChunks(photos: File[]): Promise<string[]> {
    const filePaths: string[] = [];

    for (let index = 0; index < photos.length; index++) {
      const file = photos[index];
      const totalChunks = Math.ceil(file.size / this.chunkSize);
      const fileId = `${Date.now()}_${index}`; // Уникальный идентификатор для каждого файла

      for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
        const chunk = file.slice(
          chunkIndex * this.chunkSize,
          (chunkIndex + 1) * this.chunkSize
        );
        const formData = new FormData();

        // Добавляем данные для загрузки текущего чанка
        formData.append("file_id", fileId);
        formData.append("chunk_index", chunkIndex.toString());
        formData.append("total_chunks", totalChunks.toString());
        formData.append("photo", chunk);

        // Отправка чанка на сервер
        const response = await axios.post(
          "repayment/post/review/upload-chunk",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Если файл полностью загружен, сохраняем путь к файлу
        if (response.data.file_path) {
          filePaths.push(response.data.file_path);
        }
      }
    }

    return filePaths;
  }

  // Отправка данных формы с путями к файлам на сервер
  private async sendFormData(
    reviewForm: ReviewForm,
    filePaths: string[]
  ): Promise<void> {
    const formData = new FormData();
    formData.append("article", reviewForm.article);
    formData.append("cart", reviewForm.cart.toString());
    formData.append("rating", reviewForm.rating.toString());
    formData.append("publish_date", reviewForm.publish_date);
    formData.append("review", reviewForm.review);
    formData.append("advantages", reviewForm.advantages);
    formData.append("disadvantages", reviewForm.disadvantages);

    // Добавляем пути к файлам: каждый элемент под ключом `file_paths`
    if (filePaths.length > 0) {
      filePaths.forEach((path) => formData.append("file_paths", path));
    }
    // Отправляем форму на сервер
    const response = await axios.post(
      "repayment/post/review/create",
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    if (response.status == 200) {
      store.dispatch("refreshBalance");
    }
  }
}

export default new ReviewService();
