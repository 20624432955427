<template>
  <LayuotPage>
    <div class="container-sm ps-7 ml-6">
      <div class="row">
        <!-- Grid column -->
        <div class="col-md-12">
          <h2 class="pt-3 pb-1 text-left font-bold font-up deep-purple-text">
            Тарифы
          </h2>
          <MyBreadCrumbs :crumbs="crumbs"></MyBreadCrumbs>
        </div>
      </div>
      <div v-if="isLoading">
        <div class="row">
          <CardSkeleton v-for="index in 10" :key="index"></CardSkeleton>
        </div>
      </div>
      <div class="row" v-else>
        <template v-for="(tarif, index) in tarifs" :key="index">
          <TarifCard :tarif="tarif" />
        </template>
      </div>
    </div>
  </LayuotPage>
</template>
<script setup lang="ts">
import LayuotPage from "../LayuotPage.vue";
import MyBreadCrumbs from "@/components/MyBreadCrumbs.vue";
import TarifCard from "./components/TarifCard.vue";
import PaymeentService from "./hooks/PaymentService";
import CardSkeleton from "@/components/skeletons/CardSkeleton.vue";
import "swiper/css";

const crumbs = [
  {
    text: "Финансы",
    url: "/tarifs",
  },
  {
    text: "Тарифы",
  },
];
const { isLoading, tarifs } = PaymeentService.getTarifs();
</script>
