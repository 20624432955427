<template>
  <!-- Модальное окно -->
  <VueFinalModal
    class="modal-dialog-centered"
    content-class="flex flex-col mx-auto my-1 max-w-sm p-3 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-4 rounded"
  >
    <div class="modal-content" v-if="review">
      <div
        class="modal-header d-flex justify-content-between align-items-center"
      >
        <h5 class="modal-title">Оставить отзыв</h5>
        <CloseButton @click="emit('closeicon')"></CloseButton>
      </div>
      <div class="modal-body p-0 overflow-auto" style="max-height: 400px">
        <!-- Форма отзыва -->
        <form @submit.prevent="submitReview" class="p-2">
          <!-- Выбор пола покупателя -->
          <div class="mb-2">
            <label for="genderSelect" class="form-label">Пол покупателя</label>
            <select
              id="genderSelect"
              class="form-select"
              v-model="reviewForm.gender"
              @change="updateCurrentCartPvz"
            >
              <option
                value="male"
                :disabled="
                  !review.gender_info.male ||
                  review.gender_info.male.length === 0
                "
              >
                Мужской
                {{
                  review.gender_info.male ? review.gender_info.male.length : 0
                }}
              </option>
              <option
                value="female"
                :disabled="
                  !review.gender_info.female ||
                  review.gender_info.female.length === 0
                "
              >
                Женский
                {{
                  review.gender_info.female
                    ? review.gender_info.female.length
                    : 0
                }}
              </option>
            </select>
          </div>
          <!-- Пункт выдачи -->
          <div class="mb-2">
            <label for="pickupPoint" class="form-label">Пункт выдачи</label>
            <select
              id="pickupPoint"
              class="form-select"
              v-model="reviewForm.cart"
              :disabled="currentCartPvz.length === 0"
            >
              <option
                v-for="(punkt, index) in currentCartPvz"
                :key="index"
                :value="punkt.id"
              >
                {{ "Выкуп: " + punkt.id.toString() + " " + punkt.address }}
              </option>
            </select>
          </div>
          <!-- Запланировать отзыв -->
          <div class="mb-2">
            <label for="scheduleReview" class="form-label"
              >Запланировать отзыв</label
            >
            <input
              type="datetime-local"
              class="form-control"
              id="scheduleReview"
              v-model="reviewForm.publish_date"
              :min="minDate"
              :max="maxDate"
            />
            <div v-if="errors.publishDate" style="color: red">
              {{ errors.publishDate }}
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label">Рейтинг товара</label>
            <StarRating v-model="reviewForm.rating" />
            <div v-if="errors.rating" style="color: red">
              {{ errors.rating }}
            </div>
          </div>
          <div class="mb-2">
            <label for="productAdvanteges" class="form-label"
              >Достоинства:</label
            >
            <textarea
              class="form-control"
              id="productAdvanteges"
              rows="2"
              placeholder="Достоинства товара"
              v-model="reviewForm.advantages"
            ></textarea>
          </div>
          <div class="mb-2">
            <label for="productDisadvantages" class="form-label"
              >Недостатки:</label
            >
            <textarea
              class="form-control"
              id="productDisadvantages"
              rows="2"
              placeholder="Недостатки"
              v-model="reviewForm.disadvantages"
            ></textarea>
          </div>
          <!-- Отзыв о товаре -->
          <div class="mb-2">
            <label for="productReview" class="form-label">Комментарий:</label>
            <textarea
              class="form-control"
              id="productReview"
              rows="4"
              placeholder="Например, хорошие наушники"
              v-model="reviewForm.review"
            ></textarea>
          </div>
          <!-- <div class="mb-2">
            <label for="photoUpload" class="form-label"
              >Фотографии товара</label
            >
            <input
              type="file"
              class="form-control"
              id="photoUpload"
              multiple
              @change="handleFileUpload"
            />
          </div> -->
          <label class="form-label">Фотографии и видео товара</label>
          <div class="d-flex flex-wrap gap-2 mb-2">
            <!-- Превью загруженных файлов -->
            <div
              v-for="(file, index) in photos"
              :key="index"
              class="position-relative"
              style="
                width: 100px;
                height: 100px;
                border: 1px solid #ddd;
                border-radius: 5px;
                overflow: hidden;
              "
            >
              <!-- Отображение фото или видео -->
              <img
                v-if="file.type.startsWith('image/')"
                :src="getFileUrl(file)"
                class="w-100 h-100 object-fit-cover"
                alt="Превью фото"
              />
              <video
                v-else-if="file.type.startsWith('video/')"
                :src="getFileUrl(file)"
                class="w-100 h-100 object-fit-cover"
                controls
              ></video>
              <!-- Кнопка удаления файла -->
              <button
                type="button"
                class="position-absolute top-0 end-0 btn btn-danger btn-sm"
                @click="removeFile(index)"
                style="font-size: 0.75rem; padding: 0.1rem 0.3rem"
              >
                ×
              </button>
            </div>

            <!-- Кнопка добавления нового файла -->
            <label
              v-if="photos.length < 5"
              class="d-flex align-items-center justify-content-center"
              style="
                width: 100px;
                height: 100px;
                border: 1px dashed #bbb;
                border-radius: 5px;
                cursor: pointer;
              "
            >
              <input
                type="file"
                class="d-none"
                @change="handleFileUpload"
                accept="image/*,video/*"
              />
              <span class="display-6 text-muted">+</span>
            </label>
          </div>
          <div v-if="errorMessage" class="text-danger">{{ errorMessage }}</div>
          <!-- Кнопки управления формой -->
          <div class="d-flex justify-content-between align-items-center">
            <MyButton
              type="reset"
              class="btn btn-secondary"
              :disabled="isLoading"
              >Сбросить</MyButton
            >
            <MyButton
              type="submit"
              class="btn btn-primary"
              :disabled="isLoading"
            >
              <span
                v-if="isLoading"
                class="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
              {{ isLoading ? "Отправка..." : "Отправить" }}
            </MyButton>
          </div>
        </form>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, Ref, watch } from "vue";
import MyButton from "@/components/UI/MyButton.vue";
import StarRating from "../components/StarRating.vue";
import { VueFinalModal } from "vue-final-modal";
import { IProductReview } from "../intefaces";
import CloseButton from "@/components/UI/CloseButton.vue";
import { getFormattedDate } from "@/hooks";

const props = defineProps<{
  review: Ref<IProductReview>;
}>();
const emit = defineEmits<{
  (e: "closeicon"): void;
  (event: "update", newReview: FormData): void;
}>();
// Реактивное свойство для управления видимостью модального окна
const showModal = ref(false);
const isLoading = ref(false);
const review = ref(props.review);
const reviewForm = ref({
  article: review.value.product.article,
  cart:
    review.value.gender_info.male && review.value.gender_info.male.length > 0
      ? review.value.gender_info.male[0].id
      : review.value.gender_info.female &&
        review.value.gender_info.female.length > 0
      ? review.value.gender_info.female[0].id
      : null,
  gender:
    review.value.gender_info.male && review.value.gender_info.male.length > 0
      ? "male"
      : "female",
  rating: 0,
  review: "",
  advantages: "",
  disadvantages: "",
  publish_date: "",
});
const currentCartPvz = ref(review.value.gender_info[reviewForm.value.gender]);

function updateCurrentCartPvz() {
  currentCartPvz.value = review.value.gender_info[reviewForm.value.gender];
  if (currentCartPvz.value.length > 0) {
    reviewForm.value.cart = currentCartPvz.value[0].id;
  } else {
    reviewForm.value.cart = null;
  }
}

watch(reviewForm.value, () => {
  currentCartPvz.value =
    review.value.gender_info[reviewForm.value.gender] || [];
});

// const photos = ref([] as File[]);
// function handleFileUpload(event: Event) {
//   const input = event.target as HTMLInputElement;
//   if (input.files) {
//     photos.value = Array.from(input.files);
//   }
// }

const photos = ref<File[]>([]);
const errorMessage = ref<string | null>(null);
// Метод для получения URL файла
function getFileUrl(file: File) {
  return URL.createObjectURL(file);
}
async function handleFileUpload(event: Event) {
  const input = event.target as HTMLInputElement;

  if (input.files) {
    const newFile = input.files[0];

    // Проверка: если файл — видео, проверяем его размер и продолжительность
    if (newFile.type.startsWith("video/")) {
      if (photos.value.some((file) => file.type.startsWith("video/"))) {
        errorMessage.value = "Можно загрузить только одно видео.";
        return;
      }
      if (newFile.size > 15 * 1024 * 1024) {
        errorMessage.value = "Размер видео не должен превышать 15 МБ.";
        return;
      }

      // Проверка продолжительности видео
      const videoDuration = await getVideoDuration(newFile);
      console.log(videoDuration);
      if (videoDuration > 120) {
        // 120 секунд = 2 минуты
        errorMessage.value =
          "Продолжительность видео не должна превышать 2 минуты.";
        return;
      }

      // Добавляем видео, если оно соответствует условиям
      photos.value.push(newFile);
      errorMessage.value = null; // сброс ошибки после успешного добавления
    } else if (newFile.type.startsWith("image/")) {
      // Проверка типа и размера для изображений
      if (newFile.size > 5 * 1024 * 1024) {
        errorMessage.value = "Размер изображения не должен превышать 5 МБ.";
        return;
      }

      // Проверяем, что количество изображений не превышает 5
      const imageCount = photos.value.filter((file) =>
        file.type.startsWith("image/")
      ).length;
      if (imageCount >= 5) {
        errorMessage.value = "Можно загрузить не более 5 изображений.";
        return;
      }

      // Добавляем изображение, если оно соответствует условиям
      photos.value.push(newFile);
      errorMessage.value = null; // сброс ошибки после успешного добавления
    }
  }
}

// Функция для получения продолжительности видео
function getVideoDuration(file: File): Promise<number> {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(file);
    const video = document.createElement("video");
    video.src = url;

    video.onloadedmetadata = () => {
      URL.revokeObjectURL(url); // освобождаем память
      resolve(video.duration);
    };

    video.onerror = () => {
      URL.revokeObjectURL(url); // освобождаем память
      reject(new Error("Не удалось получить продолжительность видео."));
    };
  });
}

function removeFile(index: number) {
  photos.value.splice(index, 1);
}
const errors = ref({ rating: "", publishDate: "" });
function validateReviewForm() {
  let success = true;
  if (reviewForm.value.publish_date == "") {
    reviewForm.value.publish_date = getFormattedDate(currentDate);
  }
  if (reviewForm.value.rating < 1) {
    errors.value.rating = "Поставьте рейтинг";
    success = false;
  }
  return success;
}
// Метод для обработки отправки формы
function submitReview() {
  // Логика обработки формы
  const success = validateReviewForm();
  if (success) {
    isLoading.value = true;
    const formData = new FormData();
    formData.append("article", reviewForm.value.article);
    if (reviewForm.value.cart) {
      formData.append("cart", reviewForm.value.cart.toString());
    }
    formData.append("rating", reviewForm.value.rating.toString());
    formData.append("publish_date", reviewForm.value.publish_date);
    formData.append("review", reviewForm.value.review);
    formData.append("advantages", reviewForm.value.advantages);
    formData.append("disadvantages", reviewForm.value.disadvantages);
    if (photos.value.length > 0) {
      photos.value.forEach((file) => {
        formData.append(`photos`, file);
      });
    }
    showModal.value = false;
    emit("update", formData);
  } else {
    return;
  }
}

// Получение текущей даты и даты через две недели
const currentDate = new Date();
const twoWeeksFromNow = new Date(
  currentDate.getTime() + 14 * 24 * 60 * 60 * 1000
);

const minDate = ref(getFormattedDate(currentDate));
const maxDate = ref(getFormattedDate(twoWeeksFromNow));
</script>
