import axios from "@/axiosInstance";
import { onMounted, ref } from "vue";
import {
  IRepaymentOrder,
  IQRcode,
  IRepaymentCart,
  TrackingStatus,
  IRepaymentProduct,
} from "../intefaces";
import WBService from "@/hooks/WBService";
import store from "@/store";

class RepaymentService {
  getUserRepayments() {
    const repaymentOrders = ref(Array<IRepaymentOrder>());
    const isLoading = ref(true);
    const fetching = async () => {
      try {
        const response = await axios.get("repayment/get/orders");
        repaymentOrders.value = response.data;
      } catch (e) {
        alert("Error");
      } finally {
        isLoading.value = false;
      }
    };
    onMounted(fetching);
    return { isLoading, repaymentOrders };
  }

  async archiveRepayment(id: number, action: string) {
    const response = await axios.post(
      `repayment/post/order/action?id=${id}&action=${action}`
    );
    return response.status;
  }

  getRepayment(id: string | string[]) {
    const repayment = ref(Array<IRepaymentCart>());
    const isLoading = ref(true);
    const fetching = async () => {
      try {
        const response = await axios.get("repayment/get/one", {
          params: {
            id: id,
          },
        });
        repayment.value = response.data;
      } catch (e) {
        alert("Error");
      } finally {
        isLoading.value = false;
      }
    };
    onMounted(fetching);
    return { isLoading, repayment };
  }

  async getRepaymentDubl(id: string) {
    const repayment = ref(Array<IRepaymentProduct>());
    const isLoading = ref(true);
    try {
      const response = await axios.get("repayment/post/order/dublicate", {
        params: {
          order_id: id,
        },
      });
      repayment.value = response.data;
      await Promise.all(
        repayment.value.map(async (item, index) => {
          const product = await WBService.getProduct(item.article);
          // Обновляем текущий элемент массива
          repayment.value[index] = {
            ...item, // Сохраняем существующие свойства
            ...product, // Добавляем свойства из product
          };
          repayment.value[index].punkt_id = item?.punkt_id;
          repayment.value[index].count = item?.count;
        })
      );
    } catch (e) {
      alert("Error");
    } finally {
      isLoading.value = false;
    }
    return repayment.value;
  }

  async CreateRepayment(
    products: Array<IRepaymentProduct>,
    repayment_options: object
  ) {
    const response = await axios.post("repayment/post/create", {
      items: products,
      options: repayment_options,
    });
    return response.status;
  }

  async runRepayment(id: number) {
    const token = ref("");
    // const fake_user = ref<IFakeUser>();
    const response = await axios.get("repayment/get/run", {
      params: { id: id },
    });
    token.value = response.data.token;
    if (response.status == 200) {
      store.dispatch("refreshBalance");
    }
    // fake_user.value = response.data.fake_user;
    return { token: token };
  }

  async runCheckPayment(id: number) {
    const token = ref("");
    // const fake_user = ref<IFakeUser>();
    const response = await axios.get("repayment/get/run/check", {
      params: { id: id },
    });
    token.value = response.data.token;
    // fake_user.value = response.data.fake_user;
    return { token: token };
  }

  async checkStatus(cart_id: number) {
    const isLoading = ref(true);
    const status = ref<string>("NEW");
    const error_msg = ref<string>("");
    try {
      const response = await axios.get("repayment/get/check/cart", {
        params: {
          id: cart_id,
        },
      });
      if (response.status == 200) {
        // const qr = response.data.qr_url;
        status.value = response.data.status;
        error_msg.value = response.data.error_msg;
      }
    } catch (e) {
      alert("Error");
    } finally {
      isLoading.value = false;
    }
    return { status: status, error_msg: error_msg };
  }

  getDelivery() {
    const carts = ref(Array<IRepaymentCart>());
    const isLoading = ref(true);
    const fetching = async () => {
      try {
        const response = await axios.get("repayment/get/carts");
        carts.value = response.data;
      } catch (e) {
        alert("Error");
      } finally {
        isLoading.value = false;
      }
    };
    onMounted(fetching);
    return { isLoading, carts };
  }

  async refreshdelivery() {
    try {
      await axios.post("repayment/post/delivery/refresh");
      return true;
    } catch (e) {
      return false;
    }
  }

  async getQr(cart_id: number) {
    const isLoading = ref(true);
    const cartQr = ref<IQRcode>();
    try {
      const response = await axios.get("repayment/get/cart/qr", {
        params: {
          id: cart_id,
        },
      });
      if (response.status == 200) {
        // const qr = response.data.qr_url;
        cartQr.value = response.data;
      }
    } catch (e) {
      alert("Error");
    } finally {
      isLoading.value = false;
    }
    return cartQr;
  }

  async getItemStatus(item_id: number) {
    console.log(item_id);
    const items = ref<TrackingStatus[]>([]);
    const isLoading = ref(true);
    try {
      const response = await axios.get("repayment/get/item/status", {
        params: { id: item_id },
      });
      if (response.status === 200) {
        items.value = response.data.tracking;
      }
    } catch (e) {
      console.error("Error fetching the item status:", e);
      alert("Error");
    } finally {
      isLoading.value = false;
    }
    return { isLoading, items }; // Возвращаем объект с isLoading и items
  }

  async setStatus(cart_id: number) {
    const isLoading = ref(true);
    try {
      const response = await axios.get("repayment/get/cart/failed", {
        params: {
          id: cart_id,
        },
      });
      if (response.status == 200) {
        // const qr = response.data.qr_url;
        // cartQr.value = response.data;
      }
    } catch (e) {
      alert("Error");
    } finally {
      isLoading.value = false;
    }
    // return cartQr;
  }
  async exportDelivery() {
    try {
      const response = await axios.get("repayment/export-excel", {
        responseType: "blob", // Указываем тип ответа как blob
      });

      // Создаем URL для скачивания файла
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "export.xlsx"); // Имя файла
      document.body.appendChild(link);
      link.click();

      // Удаляем объект URL после скачивания файла
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Ошибка при скачивании файла", error);
    }
  }
}

export default new RepaymentService();
