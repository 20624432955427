<template>
  <LayuotPage>
    <div class="container-sm ps-7 p-0">
      <!-- Заголовок и хлебные крошки -->
      <div class="row">
        <div class="col-12">
          <h2 class="pt-3 pb-1 text-left font-bold font-up deep-purple-text">
            Группы выкупов
          </h2>
          <MyBreadCrumbs :crumbs="crumbs" />
        </div>
      </div>

      <!-- Кнопки -->
      <div class="row">
        <!-- "Добавить выкуп" слева -->
        <div class="col-12 col-sm-6">
          <MyButton @click="$router.push('/repayment/create')">
            Добавить выкуп
          </MyButton>
        </div>
        <!-- "Видеоинструкция" справа -->
        <div class="col-12 col-sm-6 mt-2 mt-sm-0 text-sm-center">
          <MyButton @click="VideoOpen" class="btn-success">
            Видеоинструкция
          </MyButton>
        </div>
      </div>

      <!-- Блок сортировки -->
      <div class="row d-flex justify-content-between">
        <div class="col-12">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                :class="{ active: currentState === 'all' }"
                @click.prevent="sortByStatus('all')"
              >
                Все выкупы
              </a>
            </li>
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                :class="{ active: currentState === 'active' }"
                @click.prevent="sortByStatus('active')"
              >
                Неоплаченные
              </a>
            </li>
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                :class="{ active: currentState === 'done' }"
                @click.prevent="sortByStatus('done')"
              >
                Завершенные
              </a>
            </li>
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                :class="{ active: currentState === 'archive' }"
                @click.prevent="sortByStatus('archive')"
              >
                В архиве
              </a>
            </li>
          </ul>
        </div>
      </div>

      <!-- Контент: список выкупов -->
      <div class="row px-2" v-if="!isLoading">
        <template v-if="sortedOrders.length > 0">
          <OrderCart
            v-for="order in sortedOrders"
            :key="order.id"
            :order="order"
            @action="ActionOrder"
          />
        </template>
      </div>
      <!-- Скелетоны при загрузке -->
      <div class="row" v-else>
        <CardSkeleton v-for="index in 5" :key="index" />
      </div>
    </div>

    <!-- Модалки -->
    <ModalsContainer />
  </LayuotPage>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import LayuotPage from "../LayuotPage.vue";
import MyButton from "@/components/UI/MyButton.vue";
import MyBreadCrumbs from "@/components/MyBreadCrumbs.vue";
import OrderCart from "./components/OrderCart.vue";
import CardSkeleton from "@/components/skeletons/CardSkeleton.vue";
import RepaymentService from "./hooks/RepaymentService";
import { IRepaymentOrder } from "./intefaces";
import { ModalsContainer, useModal } from "vue-final-modal";
import VideoModal from "@/components/VideoModal.vue";

// Import Swiper styles
import "swiper/css";
import router from "@/router";

const crumbs = [
  {
    text: "Группы выкупов",
    url: "/repayment",
  },
  {
    text: "Выкупы",
  },
];

const { isLoading, repaymentOrders } = RepaymentService.getUserRepayments();
const sortedOrders = ref<IRepaymentOrder[]>([]);
const currentState = ref("");
const sortByStatus = (srt: string) => {
  currentState.value = srt;
  if (srt === "all") {
    sortedOrders.value = repaymentOrders.value.filter(
      (order: IRepaymentOrder) => order.is_archive === false
    );
  } else if (srt == "archive") {
    sortedOrders.value = repaymentOrders.value.filter(
      (order: IRepaymentOrder) => order.is_archive === true
    );
  } else {
    sortedOrders.value = repaymentOrders.value.filter(
      (order: IRepaymentOrder) => order.status === srt.toUpperCase()
    );
  }
};
const ActionOrder = (order: IRepaymentOrder, action: string) => {
  switch (action) {
    case "archive":
      RepaymentService.archiveRepayment(order.id, action);
      order.is_archive = !order.is_archive;
      sortByStatus(currentState.value);
      break;
    case "dublicate":
      router.push(`/repayment/create?copy_id=${order.id}`);
      break;
    default:
      break;
  }
};
watch(repaymentOrders, () => {
  sortByStatus("all"); // или любой другой статус, который вы хотите использовать по умолчанию
});

const { open: VideoOpen, close: VideoClose } = useModal({
  component: VideoModal,
  attrs: {
    videotoken: "852899a8fbc8d1fc6a0c6eb9d9ad2fc2",
    onCloseicon() {
      VideoClose();
    },
  },
});
</script>
<style scoped>
@media (max-width: 768px) {
  .card {
    width: 100%; /* На мобильных устройствах карточки будут занимать всю ширину */
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
