<template>
  <LayuotPage>
    <!-- Основной контейнер -->
    <div class="container-sm ps-7 p-0">
      <!-- Заголовок и кнопка "Видеоинструкция" -->
      <div class="row">
        <div class="col-12">
          <h2 class="pt-3 pb-1 text-left">Отзывы</h2>
          <div class="row g-3 align-items-center mb-1">
            <!-- Хлебные крошки -->
            <div class="col-12 col-md-8 d-flex flex-column">
              <MyBreadCrumbs :crumbs="crumbs" class="mt-1" />
            </div>
            <!-- Кнопка "Видеоинструкция" -->
            <div class="col-12 col-md-4 text-md-end">
              <MyButton @click="VideoOpen" class="btn btn-success">
                Видеоинструкция
              </MyButton>
            </div>
          </div>
        </div>
      </div>

      <!-- Блок сортировки и поиск -->
      <div class="row d-flex justify-content-between mb-1">
        <div class="col-12 col-sm-6 col-md-5">
          <!-- Навигация (таб-кнопки) -->
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                :class="{ active: currentState === 'reliable' }"
                @click.prevent="ChangeState('reliable')"
              >
                Надежные
              </a>
            </li>
            <!-- Пример, если нужно вернуть "Ненадежные"
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                :class="{ active: currentState === 'unreliable' }"
                @click.prevent="ChangeState('unreliable')"
              >
                Ненадежные
              </a>
            </li>
            -->
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                :class="{ active: currentState === 'published' }"
                @click.prevent="ChangeState('published')"
              >
                Опубликованные
              </a>
            </li>
          </ul>
        </div>

        <!-- Поиск по артикулу -->
        <div
          class="col-12 col-sm-6 col-md-4 mt-3 mt-sm-0 d-flex justify-content-end align-items-center"
        >
          <form class="d-flex gap-2" @submit.prevent="searchReviews">
            <TextInput
              v-model="query"
              :placeholder="'Поиск...'"
              :id="'query'"
            />
            <MyButton type="submit" class="btn btn-primary mt-2">
              Поиск
            </MyButton>
          </form>
        </div>
      </div>

      <!-- Контент: Надежные -->
      <template v-if="currentState === 'reliable'">
        <!-- Если данные загружены -->
        <div class="row" v-if="!isLoading">
          <div
            v-for="(avreview, index) in filteredReviews"
            :key="index"
            class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2"
          >
            <div class="card p-2 h-100">
              <ProductCardSmall
                :photo_url="avreview.product.photo_url"
                :article="avreview.product.article.toString()"
                :name="avreview.product.name"
              />
              <div class="card-body d-flex flex-column pb-0">
                <MyButton class="mt-auto" @click="ShowModal(avreview)">
                  Оставить отзыв
                  {{
                    (avreview.gender_info.female
                      ? avreview.gender_info.female.length
                      : 0) +
                    (avreview.gender_info.male
                      ? avreview.gender_info.male.length
                      : 0)
                  }}
                  шт.
                </MyButton>
              </div>
            </div>
          </div>
        </div>
        <!-- Если идёт загрузка -->
        <div class="row" v-else>
          <CardSkeleton v-for="index in 5" :key="index" />
        </div>
      </template>

      <!-- Контент: Опубликованные -->
      <div class="row" v-show="currentState === 'published'">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Ссылка на товар</th>
                  <th>Артикул</th>
                  <th>Отзыв</th>
                  <th>Фото</th>
                  <th>Дата публикации</th>
                  <th>Статус</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <!-- Основные данные -->
                <tr v-for="(review, index) in items" :key="index">
                  <td>{{ index + 1 }}</td>
                  <ReviewRow :review="review" />
                </tr>
                <!-- Скелетон, если нет записей, но идёт загрузка -->
                <template v-if="items.length === 0 && isReviewsLoading">
                  <RowSkeleton v-for="index in 5" :key="index" :columns="9" />
                </template>
              </tbody>
            </table>
          </div>
          <!-- Триггер для пагинации (бесконечный скролл) -->
          <div ref="loadMoreTrigger" class="load-more-trigger"></div>
        </div>
      </div>
    </div>

    <!-- Контейнер для модалок -->
    <ModalsContainer />
  </LayuotPage>
</template>

<script setup lang="ts">
import { Ref, ref, watchEffect } from "vue";
import LayuotPage from "../LayuotPage.vue";
import MyButton from "@/components/UI/MyButton.vue";
import TextInput from "@/components/UI/TextInput.vue";
import MyBreadCrumbs from "@/components/MyBreadCrumbs.vue";
import ProductCardSmall from "@/components/ProductCardSmall.vue";
import ReviewsService from "./hooks/ReviewsService";
import { ModalsContainer, useModal } from "vue-final-modal";
import { IProductReview, ReviewForm } from "./intefaces";
import ReviewModal from "./modals/ReviewModal.vue";
import RowSkeleton from "@/components/skeletons/RowSkeleton.vue";
import CardSkeleton from "@/components/skeletons/CardSkeleton.vue";
import { notify } from "@kyvg/vue3-notification";
import ReviewRow from "@/views/repayment/components/ReviewRow.vue";
import { useIntersectionObserver } from "@/hooks/useIntersectionObserver";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";
import VideoModal from "@/components/VideoModal.vue";

const crumbs = [
  {
    text: "Отзывы",
    url: "/reviews",
  },
  {
    text: "Отзывы",
  },
];
const currentState = ref("reliable");
const ChangeState = function (state: string) {
  unobserve();
  currentState.value = state;
  refresh();
  observe();
};

const fetchData = (page: number, pageSize: number) =>
  ReviewsService.getPublishedReviews(page, pageSize);

// Используем useInfiniteScroll с fetchData
const {
  items,
  loadMore,
  refresh,
  isLoading: isReviewsLoading,
} = useInfiniteScroll(fetchData, 20);
// Подключаем IntersectionObserver, передавая loadMore как callback
const {
  target: loadMoreTrigger,
  observe,
  unobserve,
} = useIntersectionObserver(loadMore, { rootMargin: "100px" });
const { isLoading, AvaibleReviews } = ReviewsService.getAvailableReview(true);
const currentProduct = ref<IProductReview>(AvaibleReviews.value[0]);
const ShowModal = function (product: IProductReview) {
  currentProduct.value = product;
  open();
};
const filteredReviews: Ref<Array<IProductReview>> = ref([
  ...AvaibleReviews.value,
]);
const query = ref("");
const searchReviews = () => {
  isLoading.value = true;
  if (query.value) {
    filteredReviews.value = AvaibleReviews.value.filter(
      (review: IProductReview) =>
        review.product.article.toString() === query.value
    );
  } else {
    filteredReviews.value = AvaibleReviews.value;
  }
  isLoading.value = false;
};

watchEffect(() => {
  searchReviews();
});
const { open: VideoOpen, close: VideoClose } = useModal({
  component: VideoModal,
  attrs: {
    videotoken: "264eec44a63e7668a28dc9f859cf798f",
    onCloseicon() {
      VideoClose();
    },
  },
});

const { open, close } = useModal({
  component: ReviewModal,
  attrs: {
    review: currentProduct,
    onCloseicon() {
      close();
    },
    async onUpdate(newReviewFormData) {
      const photos = Array.from(newReviewFormData.getAll("photos")).filter(
        (file): file is File => file instanceof File
      ); // Убедимся, что это только файлы

      const reviewData: ReviewForm = {
        article: newReviewFormData.get("article") as string,
        cart: parseInt(newReviewFormData.get("cart") as string, 10),
        rating: parseInt(newReviewFormData.get("rating") as string, 10),
        publish_date: newReviewFormData.get("publish_date") as string,
        review: newReviewFormData.get("review") as string,
        advantages: newReviewFormData.get("advantages") as string,
        disadvantages: newReviewFormData.get("disadvantages") as string,
      };

      // Загружаем данные отзыва с помощью функции для загрузки частями
      await ReviewsService.uploadFilesAndForm(reviewData, photos);
      close();
      notify({
        text: "Отзыв успешно добавлен",
      });
      AvaibleReviews.value =
        ReviewsService.getAvailableReview(true).AvaibleReviews.value;
      unobserve();
      refresh();
      observe();
    },
  },
});
</script>
<style scoped>
@media (max-width: 576px) {
  .nav-item {
    width: 100%;
    text-align: center;
  }
  .nav-item .nav-link {
    width: 100%;
  }
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.card-body {
  display: flex;
  flex-direction: column;
}

.card {
  display: flex;
  flex-direction: column;
}
.load-more-trigger {
  height: 1px;
}
</style>
