<template>
  <button
    class="rounded-circle mobile-menu-button"
    @click="toggleSidebar"
    v-if="isAuth"
  >
    ☰
  </button>

  <div
    v-if="isSidebarOpen && isAuth"
    class="overlay"
    @click="toggleSidebar"
  ></div>

  <nav
    v-if="isAuth"
    class="sidebar"
    :class="{ close: isClose, open: isSidebarOpen }"
  >
    <header :class="{ 'collapsed-header': isClose }">
      <div class="logo-area" v-if="!isClose">
        <!-- Полный вид логотипа и пользователя -->
        <div class="text logo-text text-wrap text-center ml-2">
          Вы <a href="/profile" class="name">{{ store.state.user.email }}</a>
          <span class="profession text-left"
            >Баланс: {{ store.state.balance }}</span
          >
        </div>
        <li class="nav-link active mb-3">
          <a class="active" href="#" @click="() => open()">
            <i class="bx icon bx-wallet active"></i>
            <span class="text nav-text active">Пополнить</span>
          </a>
        </li>
      </div>

      <div class="mini-logo-area" v-else>
        <!-- Сокращенный вид только с иконкой 'bx-wallet' -->
        <div class="row m-2">
          <i
            class="bx icon bx-user mb-5 icon-hover"
            @click="router.push('/profile')"
          ></i>
          <i class="bx icon bx-wallet icon-hover" @click="() => open()"></i>
        </div>
      </div>

      <i
        class="bx toggle-icon"
        :class="{
          toggle: !isClose,
          'bx-chevron-left': isClose,
          'bx-chevron-right': !isClose,
        }"
        @click="toggleClick"
      ></i>
    </header>

    <div class="menu-bar">
      <div class="menu">
        <!-- <li class="search-box">
          <i class="bx bx-search icon"></i>
          <input type="text" placeholder="Поиск..." />
        </li> -->

        <ul class="menu-links">
          <template v-for="(value, name) in urls" :key="name">
            <div class="mt-3 block">
              {{ name }}
            </div>
            <li class="nav-link" v-for="url in value" :key="url.url">
              <a
                href="#"
                @click="$router.push(url.url)"
                :class="{
                  active: $router.currentRoute.value.path.includes(url.url),
                }"
              >
                <i
                  class="bx icon"
                  :class="[
                    {
                      active: $router.currentRoute.value.path.includes(url.url),
                    },
                    url.class,
                  ]"
                ></i>
                <span
                  class="text nav-text"
                  :class="{
                    active: $router.currentRoute.value.path.includes(url.url),
                  }"
                  >{{ url.text }}</span
                >
              </a>
            </li>
          </template>
        </ul>
      </div>

      <div class="bottom-content">
        <li class="" @click="logOut">
          <a href="#">
            <i class="bx bx-log-out icon"></i>
            <span class="text nav-text">Выход</span>
          </a>
        </li>

        <!-- <li class="mode">
          <div class="sun-moon">
            <i class="bx bx-moon icon moon"></i>
            <i class="bx bx-sun icon sun"></i>
          </div>
          <span class="mode-text text">Темная тема</span>

          <div class="toggle-switch" @click="changeTheme()">
            <span class="switch"></span>
          </div>
        </li> -->
      </div>
    </div>
  </nav>
  <ModalsContainer />
</template>
<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ModalsContainer, useModal } from "vue-final-modal";
import ServicePayment from "@/views/payment/modals/ServicePayment.vue";

const router = useRouter();
const store = useStore();
const urls = {
  "Выкупы ": [
    {
      url: "/repayment",
      class: "bx-credit-card",
      text: "Выкупы",
    },
    {
      url: "/delivery",
      class: "bx-bar-chart-alt-2",
      text: "Доставка",
    },
    {
      url: "/reviews",
      class: "bx-bell",
      text: "Отзывы",
    },
  ],
  "Прогрев карточки": [
    {
      url: "/likes",
      class: "bx-heart",
      text: "Лайки",
    },
    {
      url: "/questions",
      class: "bx-question-mark",
      text: "Вопросы",
    },
    {
      url: "/carts",
      class: "bxs-cart-add",
      text: "Корзина",
    },
    {
      url: "/reviewlike",
      class: "bx bxs-like",
      text: "Лайки на отзывы",
    },
  ],
  "Финансы ": [
    {
      url: "/tarifs",
      class: "bx-wallet",
      text: "Тарифы",
    },
    {
      url: "/history",
      class: "bx-wallet",
      text: "Финансы",
    },
  ],
  "Остальное ": [
    {
      url: "/analitics",
      class: "bx-pie-chart-alt",
      text: "Аналитика",
    },
  ],
};
const isClose = computed(() => store.state.isCollapse);
const darkMode = ref(false);
store.dispatch("Restore");
// console.log(store.getters.isLoggedIn);
const isAuth = computed(() => Boolean(store.getters.isLoggedIn));
onMounted(() => {
  store.dispatch("Restore");
});
// const changeTheme = () => {
//   const el = document.body;
//   darkMode.value = !darkMode.value;
//   el.classList.toggle("dark");
// };
const toggleClick = () => {
  // isClose.value = !isClose.value;
  store.dispatch("ToggleCollapse");
};
const isSidebarOpen = ref(false);
const toggleSidebar = () => {
  isSidebarOpen.value = !isSidebarOpen.value;
};
const logOut = () => {
  store.dispatch("LogOut");
  router.push("/auth");
};
const { open, close } = useModal({
  component: ServicePayment,
  attrs: {
    onCloseicon() {
      close();
    },
  },
});
</script>
<style>
/* Google Font Import - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
ul.menu-links {
  padding-left: 0rem;
}
.sidebar.close .block {
  font-size: small;
  text-wrap: wrap;
  padding: auto;
}
:root {
  /* ===== Colors ===== */
  /* --body-color: #e4e9f7; */
  --sidebar-color: #f2f2f2;
  --primary-color: #695cfe;
  --primary-color-light: #e1e0e9;
  --toggle-color: #ddd;
  --text-color: #707070;

  /* ====== Transition ====== */
  --tran-03: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.3s ease;
  --tran-05: all 0.3s ease;
}

body {
  min-height: 100vh;
  background-color: var(--body-color);
  transition: var(--tran-05);
}

::selection {
  background-color: var(--primary-color);
  color: #fff;
}

body.dark {
  --body-color: #18191a;
  --sidebar-color: #242526;
  --primary-color: #3a3b3c;
  --primary-color-light: #3a3b3c;
  --toggle-color: #fff;
  --text-color: #ccc;
}

/* ===== Sidebar ===== */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  padding: 10px 14px 120px;
  background: var(--sidebar-color);
  margin: 2px;
  border-radius: 10px;
  transition: var(--tran-05);
  z-index: 160;
}
.sidebar.close {
  width: 88px;
}
/* .nav-link {
  padding: 0;
} */
/* ===== Reusable code - Here ===== */
.sidebar li {
  height: 50px;
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.sidebar header .image,
.sidebar .icon {
  min-width: 60px;
  border-radius: 6px;
}

.sidebar .icon {
  min-width: 60px;
  border-radius: 6px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.sidebar .text,
.sidebar .icon {
  color: var(--text-color);
  transition: var(--tran-03);
}

.sidebar .text {
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  opacity: 1;
}
.sidebar.close .text {
  opacity: 0;
}
/* =========================== */

.sidebar header {
  position: relative;
  padding: 10px 10px 0px;
  border: 1px solid rgb(148, 146, 146);
  border-radius: 5px;
  background-color: #ffffff;
}

.sidebar header .image-text {
  display: flex;
  align-items: center;
}
.sidebar header .logo-text {
  display: flex;
  flex-direction: column;
}
header .image-text .name {
  margin-top: 2px;
  font-size: 18px;
  font-weight: 600;
}

header .image-text .profession {
  font-size: 16px;
  margin-top: -2px;
  display: block;
}

.sidebar header .image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar header .image img {
  width: 40px;
  border-radius: 6px;
}

.sidebar header .toggle {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%) rotate(180deg);
  height: 25px;
  width: 25px;
  background-color: var(--primary-color);
  color: var(--sidebar-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  cursor: pointer;
  transition: var(--tran-05);
}

body.dark .sidebar header .toggle {
  color: var(--text-color);
}

.sidebar.close .toggle {
  transform: translateY(-50%) rotate(0deg);
}

/* .sidebar .menu {
  margin-top: 40px;
} */

.sidebar li.search-box {
  border-radius: 6px;
  background-color: var(--primary-color-light);
  cursor: pointer;
  transition: var(--tran-05);
}

.sidebar li.search-box input {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  background-color: var(--primary-color-light);
  color: var(--text-color);
  border-radius: 6px;
  font-size: 17px;
  font-weight: 500;
  transition: var(--tran-05);
}
.sidebar li a {
  list-style: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  text-decoration: none;
  transition: var(--tran-03);
}

.sidebar li a:hover {
  background-color: var(--primary-color);
}
.sidebar li a:hover .icon,
.sidebar li a:hover .text {
  color: var(--sidebar-color);
}
body.dark .sidebar li a:hover .icon,
body.dark .sidebar li a:hover .text {
  color: var(--text-color);
}

.sidebar li a.active {
  background-color: var(--primary-color);
}
.sidebar li a .icon.active,
.sidebar li a .text.active {
  color: var(--sidebar-color);
}
body.dark .sidebar li a .icon.active,
body.dark .sidebar li a .text.active {
  color: var(--text-color);
}

.sidebar .menu-bar {
  height: calc(100% - 55px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}
.menu-bar::-webkit-scrollbar {
  display: none;
}
.sidebar .menu-bar .mode {
  border-radius: 6px;
  background-color: var(--primary-color-light);
  position: relative;
  transition: var(--tran-05);
}

.menu-bar .mode .sun-moon {
  height: 50px;
  width: 60px;
}

.mode .sun-moon i {
  position: absolute;
}
.mode .sun-moon i.sun {
  opacity: 0;
}
body.dark .mode .sun-moon i.sun {
  opacity: 1;
}
body.dark .mode .sun-moon i.moon {
  opacity: 0;
}

.menu-bar .bottom-content .toggle-switch {
  position: absolute;
  right: 0;
  height: 100%;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}
.toggle-switch .switch {
  position: relative;
  height: 22px;
  width: 40px;
  border-radius: 25px;
  background-color: var(--toggle-color);
  transition: var(--tran-05);
}

.switch::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  background-color: var(--sidebar-color);
  transition: var(--tran-04);
}

body.dark .switch::before {
  left: 20px;
}

.home {
  position: absolute;
  top: 0;
  top: 0;
  left: 250px;
  height: 100vh;
  width: calc(100% - 250px);
  background-color: var(--body-color);
  transition: var(--tran-05);
}
.home .text {
  font-size: 30px;
  font-weight: 500;
  color: var(--text-color);
  padding: 12px 60px;
}

.sidebar.close ~ .home {
  left: 78px;
  height: 100vh;
  width: calc(100% - 78px);
}
body.dark .home .text {
  color: var(--text-color);
}
.collapsed-header {
  background-color: #f2f2f2; /* Пример изменения фона */
  justify-content: center; /* Центрирование иконки */
  padding: 10px;
}

.collapsed-header .mini-logo-area {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  border-radius: 50%;
}
.toggle-icon:hover {
  background-color: var(--primary-color);
}

/* Стили для иконки при сворачивании */
.sidebar.close .toggle-icon {
  transform: rotate(180deg);
}

/* Дополнительные стили для свернутого состояния */
.sidebar.close .toggle-icon.toggle {
  transform: translateY(-50%) rotate(0deg);
}
.icon-hover {
  transition: transform 0.3s ease, background-color 0.3s ease;
  display: inline-flex; /* Убедитесь, что иконка центрирована */
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 10px;
}

.icon-hover:hover {
  transform: scale(0.9); /* Увеличивает иконку на 20% */
  background-color: var(--primary-color-light); /* Добавление фонового цвета */
  color: var(--primary-color);
}
.mobile-menu-button {
  position: fixed;
  top: 10px;
  left: 10px;

  width: 40px;
  height: 40px;

  display: flex; /* Вместо display: none; для демонстрации */
  align-items: center; /* Вертикальное выравнивание */
  justify-content: center; /* Горизонтальное выравнивание */

  background-color: var(--primary-color);
  color: white;
  border: none;
  font-size: 20px;
  cursor: pointer;
  line-height: 1;
  padding: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease;
  z-index: 200;

  /* Сбрасываем дефолтные стили iOS */
  -webkit-appearance: none;
  appearance: none;
  /* Если нужно убрать подсветку при тапе: */
  -webkit-tap-highlight-color: transparent;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 150;
}
/* Показывать кнопку мобильного меню на мобильных устройствах */
@media (max-width: 768px) {
  .mobile-menu-button {
    display: flex;
  }

  /* Скрывать боковую панель по умолчанию на мобильных устройствах */
  .sidebar {
    display: none;
  }

  /* Показывать боковую панель, когда она открыта */
  .sidebar.open {
    display: block;
    animation: slideIn 0.3s forwards;
  }

  .sidebar.close {
    animation: slideOut 0.3s forwards;
  }

  i.bx.toggle-icon {
    display: none;
  }
}
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>
