<template>
  <LayuotPage>
    <div class="container-sm ps-7">
      <div class="card-body">
        <!-- Grid row -->
        <div class="row">
          <div class="col-md-8">
            <h2 class="pt-3 pb-4 font-bold font-up deep-purple-text">
              Добавить выкуп
            </h2>
          </div>
          <div class="col-12 col-sm-auto ms-auto mt-3">
            <MyButton @click="$router.push('/repayment')">Закрыть</MyButton>
          </div>
        </div>
        <div class="row">
          <!-- Grid column -->
          <div class="col-md-12">
            <form class="row g-3 align-items-center" @submit.prevent>
              <div class="col-12 col-sm-4">
                <label for="aticle" class="visually-hidden"
                  >Введите артикул</label
                >
                <TextInput
                  v-model="article"
                  :placeholder="'Введите артикул'"
                  :id="'article'"
                ></TextInput>
              </div>
              <div class="col-6 col-sm-auto mt-4">
                <MyButton @click="AppendProduct">Добавить</MyButton>
              </div>
              <div class="col-6 col-sm-auto mt-4">
                <MyButton @click="openManyAddModal()">
                  <i class="bx bx-list-plus"></i>
                </MyButton>
              </div>
              <div
                class="col-6 col-sm-auto mt-4"
                v-if="permissions && permissions.user_fresh"
              >
                <input
                  class="form-check-input me-2"
                  type="checkbox"
                  v-model="repaymentOptions.user_fresh"
                  aria-label="..."
                />
                Чистые аккаунты
              </div>
              <div
                class="col-6 col-sm-auto mt-4"
                v-if="permissions && permissions.dbs"
              >
                <input
                  class="form-check-input me-2"
                  type="checkbox"
                  v-model="repaymentOptions.dbs"
                  aria-label="..."
                />
                DBS
              </div>
              <div
                class="col-12 col-sm-auto ms-auto mt-4"
                v-if="repaymentProducts.length > 0"
              >
                <MyButton @click="AppendPunct(-1)">
                  <i class="bx bx-map-alt"></i> Добавить ПВЗ
                </MyButton>
              </div>
            </form>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
        <!--Table-->
        <table class="table">
          <thead>
            <tr>
              <th class="col-1">#</th>
              <th class="col-1">Артикул</th>
              <th class="col-1">Название</th>
              <th class="col-1">Цена</th>
              <th class="col-2">Количество</th>
              <th class="col-2">Размер</th>
              <th class="col-2">Пол</th>
              <th class="col-2">Ключевой запрос</th>
              <th class="col-1">Адрес</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <transition-group name="repayment-list">
              <template
                v-for="(product, index) in repaymentProducts"
                :key="product.id"
              >
                <tr>
                  <td
                    class="col-1"
                    :class="{ 'is-disabled-row': product.is_disabled }"
                  >
                    <ImageContainer
                      :photo_url="product.photo_url"
                    ></ImageContainer>
                  </td>
                  <td :class="{ 'is-disabled-row': product.is_disabled }">
                    <a
                      :href="WBService.getWBURL(product.article)"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ product.article }}</a
                    >
                  </td>
                  <td
                    class="col-1"
                    :class="{ 'is-disabled-row': product.is_disabled }"
                  >
                    <div class="text-truncate">
                      {{ product.name.slice(0, 20) }}...
                    </div>
                  </td>
                  <td :class="{ 'is-disabled-row': product.is_disabled }">
                    {{ product.cur_price }}
                  </td>
                  <td :class="{ 'is-disabled-row': product.is_disabled }">
                    <NumberInput
                      v-model="product.count"
                      :step="1"
                      :max="product.max_count"
                    />
                  </td>
                  <td :class="{ 'is-disabled-row': product.is_disabled }">
                    <MySelect
                      :options="product.sizes"
                      v-model="product.size"
                      v-if="Object.keys(product.sizes).length > 0"
                      @change="ChangeCurPrice(product)"
                    />
                    <span v-else-if="product.is_disabled">Нет в наличии</span>
                    <span v-else>Нет размера</span>
                  </td>
                  <td :class="{ 'is-disabled-row': product.is_disabled }">
                    <MySelect :options="gender" v-model="product.gender" />
                  </td>
                  <td :class="{ 'is-disabled-row': product.is_disabled }">
                    <div>
                      <TextInput
                        v-model="product.query"
                        :placeholder="'Введите ключевые слова'"
                        :id="'query'"
                        @input="handleInput(product)"
                        v-if="!product.query_confirm"
                      >
                        <span
                          v-if="product.query_loading"
                          class="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </TextInput>
                      <template v-else>
                        {{ product.query }}
                        <IconButton
                          :icon-class="'bx bxs-pencil'"
                          @click="ChangeQuery(product)"
                        />
                      </template>
                      <ToolTip
                        v-if="product.id == activeItem"
                        :product="product"
                        :show="showTooltip"
                        @close-tip="closeToolTip"
                      >
                        <template
                          v-if="product.index == -1 && product.page == -1"
                        >
                          <div>
                            <i class="fas fa-check-circle text-success"></i>
                            Товар по указанному запросу не найден. Попробуйте
                            другой запрос.
                            <br />
                          </div>
                        </template>
                        <template
                          v-else-if="product.index == -2 && product.page == -2"
                        >
                          <div>
                            <i class="fas fa-check-circle text-success"></i>
                            Поиск будет осуществлен по артикулу
                            {{ product.article }}
                            <br />
                          </div>
                          <button
                            class="btn text-light bg-secondary"
                            @click="confirmQuery(product)"
                          >
                            Применить
                          </button>
                        </template>
                        <template
                          v-else-if="
                            product.index != undefined &&
                            product.page != undefined
                          "
                        >
                          <i class="fas fa-check-circle text-success"></i>
                          Поиск будет осуществлен по ключу "{{
                            product.query
                          }}". Товар находится на {{ product.page }} странице,
                          позиция {{ product.index }}.
                          <br />
                          <button
                            class="btn text-light bg-secondary"
                            @click="confirmQuery(product)"
                          >
                            Применить
                          </button>
                        </template>
                        <template v-else>
                          <div class="d-flex justify-content-center">
                            <div
                              class="spinner-border text-primary"
                              role="status"
                            >
                              <span class="visually-hidden">Загрузка...</span>
                            </div>
                          </div>
                        </template>
                      </ToolTip>
                    </div>
                  </td>
                  <td
                    :class="{
                      'is-disabled-row':
                        product.is_disabled || repaymentOptions.dbs,
                    }"
                  >
                    <template v-if="product.punkt_id == 0">
                      <MyButton @click="AppendPunct(index)"
                        >Добавить ПВЗ</MyButton
                      >
                    </template>
                    <template v-else>
                      <p class="address-text">
                        {{ product.address }}
                        <IconButton
                          :icon-class="'bx bxs-pencil'"
                          @click="AppendPunct(index)"
                        />
                      </p>
                    </template>
                  </td>
                  <td :class="{ 'is-disabled-row': product.is_disabled }">
                    <IconButton
                      :icon-class="'bx icon bx-duplicate'"
                      @click="Dublicate(product)"
                    />
                  </td>
                  <td>
                    <CloseButton @click="removeItem(product)"></CloseButton>
                  </td>
                </tr>
              </template>
            </transition-group>
          </tbody>
        </table>

        <!--Table-->
        <div
          class="row justify-content-beetween"
          v-if="repaymentProducts.length > 0"
        >
          <div class="col-5 d-flex justify-content-start">
            Количество товаров: {{ total.totalCount }}
            <br />
            Сумма выкупа: {{ total.totalPrice }}
          </div>
          <div class="col-5 d-flex justify-content-end">
            <MyButton @click="SendRepayment"> Создать выкуп </MyButton>
          </div>
        </div>
        <ModalsContainer :mode="mapMode" />
      </div>
    </div>
  </LayuotPage>
</template>
<script setup lang="ts">
import { ref, computed, onUnmounted, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import NumberInput from "@/components/UI/NumberInput.vue";
// import { NumberInput, MySelect } from "@/components/UI";
import LayuotPage from "../LayuotPage.vue";
import MySelect from "@/components/UI/MySelect.vue";
import MyButton from "@/components/UI/MyButton.vue";
import CloseButton from "@/components/UI/CloseButton.vue";
import IconButton from "@/components/UI/IconButton.vue";
import TextInput from "@/components/UI/TextInput.vue";
import ImageContainer from "@/components/UI/ImageContainer.vue";
// import { getAllPunkts } from "./hooks/getPunkts";
import PunctYaMap from "./modals/PunctYaMap.vue";
import ManyAddModal from "./modals/ManyAddModal.vue";
import { ModalsContainer, useModal } from "vue-final-modal";
import { IRepaymentProduct } from "./intefaces";
import WBService from "@/hooks/WBService";
import { getId } from "@/hooks/index";
import ToolTip from "@/components/ToolTip.vue";
import RepaymentService from "./hooks/RepaymentService";
// import { notify } from "@kyvg/vue3-notification";
import { useNotification } from "@kyvg/vue3-notification";
import { sleep } from "@/hooks";
import { useStore } from "vuex";
import { IState } from "@/store/index";
import AuthService from "../auth/hooks/AuthService";

const { notify } = useNotification();
const store = useStore<IState>();
const route = useRoute();
const router = useRouter();
const article = ref("");
const puncts = ref(Array<number>());
const gender = [
  { name: "Муж.", value: "male" },
  { name: "Жен.", value: "female" },
];
const errors = ref({
  article: true,
});
let debounceTimeout: ReturnType<typeof setTimeout>;
const repaymentProducts = ref(Array<IRepaymentProduct>());
const permissions = ref<object>();
const repaymentOptions = ref({
  user_fresh: false,
  group: true,
  dbs: false,
});
const DublicateRepayment = async () => {
  if (route.query.copy_id) {
    repaymentProducts.value = await RepaymentService.getRepaymentDubl(
      route.query.copy_id.toString()
    );
    repaymentProducts.value.map(async (item) => {
      await SearchProductPosition(item);
      confirmQuery(item);
    });
  }
};
onMounted(async () => {
  permissions.value = await AuthService.getPermissions();
  console.log(permissions.value);
  await DublicateRepayment();
});
async function addRepaymentProduct(article: string) {
  try {
    const responseProduct = await WBService.getProduct(article);
    // let product: any = responseProduct;
    // console.log(product);
    // product.cost = promises[1].data.products[0].salePriceU;
    repaymentProducts.value.push(responseProduct as IRepaymentProduct);
  } catch {
    notify({
      text: "Продукт не найден",
    });
  }
}
const AppendProduct = async () => {
  const regex = /^[\d]{5,9}$/;
  errors.value.article = regex.test(article.value);
  if (errors.value.article) {
    addRepaymentProduct(article.value);
  } else {
    errors.value.article = false;
  }
};

const showTooltip = ref(false);
const activeItem = ref("0");

const SearchProductPosition = async (
  product: IRepaymentProduct,
  retries = 20
) => {
  product.query_confirm = false;
  product.query_loading = true;
  const regex = /^[\d]{5,9}$/;
  const is_article = regex.test(product.query.trim());
  if (is_article && product.query.trim() === product.article) {
    product.index = -2;
    product.page = -2;
    return;
  }
  const token = await WBService.SearchProductTask(
    product.article,
    product.query
  );
  if (!token) {
    // Если не удалось получить токен, завершаем выполнение
    return;
  }
  let result;
  while (retries > 0 && active) {
    if (retries == 20) {
      sleep(3);
    }
    try {
      result = await WBService.getTaskResult(token);
      if (result) {
        showTooltip.value = true;
        product.index = result.index;
        product.page = result.page;
        product.query_loading = false;
        return;
      } else {
        // Если результат еще не готов и есть попытки, ожидаем 2 секунды перед повторным запросом
        await sleep(5);
        retries--;
      }
    } catch (error: any) {
      if (error.name === "AbortError") {
        console.log("Request was aborted");
        product.query_loading = false;
        return;
      }
      console.error("Error fetching task result:", error);
    }
  }
  if (retries == 0 && !result) {
    product.index = 0;
    product.page = 0;
  }
};
let active = true;
const handleInput = (product: IRepaymentProduct) => {
  // Если уже существует таймер, сбросить его
  clearTimeout(debounceTimeout);
  if (product.id) {
    activeItem.value = product.id;
    showTooltip.value = true;
    product.page = undefined;
    product.index = undefined;
    // Установить новый таймер
    debounceTimeout = setTimeout(async () => {
      await SearchProductPosition(product);
    }, 2000); // Задержка в 500 мс
  }
};
onUnmounted(() => {
  active = false;
  clearTimeout(debounceTimeout);
});
const closeToolTip = () => {
  showTooltip.value = false;
};

const confirmQuery = (product: IRepaymentProduct) => {
  if (product.index != -1 && product.page != -1) {
    product.query_confirm = true;
  }
  showTooltip.value = false;
};

const ChangeQuery = (product: IRepaymentProduct) => {
  product.query_confirm = false;
};
const ChangeCurPrice = (product: IRepaymentProduct) => {
  const size = product.sizes.find((size) => size.value === product.size);
  if (size) {
    product.cur_price = size.price / 100;
    product.max_count = size.qty;
    product.count = 1;
  }
};
const total = computed(() => {
  // get() {
  let obj = { totalPrice: 0, totalCount: 0 };
  repaymentProducts.value.forEach((product: IRepaymentProduct) => {
    if (!product.is_disabled) {
      obj.totalPrice += product.count * product.cur_price;
      obj.totalCount += product.count;
    }
  });
  return obj;
  // },
});
const currentProduct = ref({
  id: 0,
  index: 0,
  address: "",
});
const mapMode = ref(true);
const AppendPunct = function (index: number) {
  if (index !== -1) {
    currentProduct.value.index = index;
    mapMode.value = true;
  } else {
    mapMode.value = false;
  }
  open();
};
const Dublicate = function (product: IRepaymentProduct) {
  const new_product = Object.assign({}, product);
  new_product.id = getId();
  repaymentProducts.value.push(new_product);
};
const removeItem = function (product: IRepaymentProduct) {
  // repaymentProducts.value.pop(product);
  repaymentProducts.value = repaymentProducts.value.filter(
    (p: IRepaymentProduct) => p.id !== product.id
  );
};
const SendRepayment = async () => {
  // console.log(repaymentProducts.value[0]);
  let confirmrepayment = true;
  repaymentProducts.value.forEach((product: IRepaymentProduct) => {
    if (!product.is_disabled) {
      if (product.punkt_id == 0 && !repaymentOptions.value.dbs) {
        confirmrepayment = false;
        notify({
          title: "Уведомление",
          text: `Не выбран пвз у товара с артикулом ${product.article}`,
        });
      }
      if (!product.query_confirm) {
        confirmrepayment = false;
        notify({
          title: "Уведомление",
          text: `Не подтвержден запрос поиска у товара с артикулом ${product.article}`,
        });
      }
    }
  });
  const repayment_products = repaymentProducts.value.filter((item) => {
    if (!item.is_disabled) {
      return item;
    }
  });
  if (repayment_products.length == 0) {
    confirmrepayment = false;
    notify({
      title: "Уведомление",
      text: `Нет доступных продуктов для оформления выкупа`,
    });
  }
  if (confirmrepayment) {
    const status = await RepaymentService.CreateRepayment(
      repayment_products,
      repaymentOptions.value
    );
    if (status == 200) {
      router.push("/repayment");
    }
  }
};
const { open, close } = useModal({
  component: PunctYaMap,
  attrs: {
    // markers: markers,
    mode: mapMode,
    onCloseicon() {
      close();
    },
    onUpdate(index: Array<number> | number) {
      if (typeof index == "number") {
        let marker = store.state.points.markers[index];
        // console.log(index);
        repaymentProducts.value[currentProduct.value.index].punkt_id = marker
          .properties?.id as number;
        repaymentProducts.value[currentProduct.value.index].address = marker
          .properties?.dsc as string;
      } else {
        puncts.value = index;
        repaymentProducts.value.forEach(
          (product: IRepaymentProduct, index: number) => {
            if (product.punkt_id == 0) {
              let marker =
                store.state.points.markers[
                  puncts.value[index % puncts.value.length]
                ];
              product.punkt_id = marker.properties?.id as number;
              product.address = marker.properties?.dsc as string;
            }
          }
        );
        close();
      }
    },
  },
});
const { open: openManyAddModal, close: closeManyAddModal } = useModal({
  component: ManyAddModal,
  attrs: {
    onCloseicon() {
      closeManyAddModal();
    },
    onUpdate(articles: Array<string>) {
      articles.forEach(async (earticle) => {
        addRepaymentProduct(earticle);
      });
      closeManyAddModal();
    },
  },
});
onMounted(() => {
  store.dispatch("points/fetchMarkers");
  // store.dispatch("points/setCurrentPoint");
});
</script>
<style scoped>
td.is-disabled-row {
  pointer-events: none;
  opacity: 0.5;
}
.hm-gradient {
  background-image: linear-gradient(
    to top,
    #f3e7e9 0%,
    #e3eeff 99%,
    #e3eeff 100%
  );
}
.address-text {
  font-size: small;
}
.darken-grey-text {
  color: #2e2e2e;
}

.input-group.md-form.form-sm.form-2 input {
  border: 1px solid #bdbdbd;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.input-group.md-form.form-sm.form-2 input.purple-border {
  border: 1px solid #9e9e9e;
}

.input-group.md-form.form-sm.form-2
  input[type="text"]:focus:not([readonly]).purple-border {
  border: 1px solid #ba68c8;
  box-shadow: none;
}

.form-2 .input-group-addon {
  border: 1px solid #ba68c8;
}

.danger-text {
  color: #ff3547;
}

.success-text {
  color: #00c851;
}

.table-bordered.red-border,
.table-bordered.red-border th,
.table-bordered.red-border td {
  border: 1px solid #ff3547 !important;
}

.table.table-bordered th {
  text-align: center;
}
.repayment-list-item {
  display: inline-block;
  margin-right: 10px;
}
.repayment-list-enter-active,
.repayment-list-leave-active {
  transition: all 0.4s ease;
}
.repayment-list-enter-from,
.repayment-list-leave-to {
  opacity: 0;
  transform: translateX(130px);
}
.repayment-list-move {
  transition: transform 0.4s ease;
}
@media (max-width: 576px) {
  .col-12 {
    margin-bottom: 1rem;
  }

  .ms-auto {
    margin-left: auto !important;
  }

  .mt-3 {
    margin-top: 1rem !important;
  }

  .mt-4 {
    margin-top: 1.5rem !important;
  }
}
</style>
