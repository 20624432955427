<template>
  <VueFinalModal
    class="flex justify-center items-center"
    content-class="flex flex-col max-w-xl mx-4 p-4 mt-5 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 rounded"
  >
    <div class="w-100 d-flex justify-content-between align-items-center">
      <h5 class="modal-title">Выберите пункты выдачи</h5>
      <CloseButton @click="emit('closeicon')" />
    </div>
    <div class="inputs">
      <label>
        Поиск
        <input
          v-model="search"
          autocomplete="off"
          list="search"
          placeholder="Начните вводить для поиска"
          type="text"
          class="form-input mb-2"
        />
        <datalist id="search">
          <option
            v-for="(item, index) in searchResponse ?? []"
            :key="item.geometry?.coordinates.join(',') ?? index"
            :value="item.geometry?.coordinates"
            class="search-option"
          >
            {{ item.properties.name }} ({{ item.properties.description }})
          </option>
        </datalist>
      </label>
      <!-- <MyButton @click="SearchPlace()">Найти</MyButton> -->
    </div>
    <div class="row d-flex flex-wrap">
      <div class="col-12 col-md-3 fixed-height-container">
        <ul
          class="list-group"
          :class="{
            'height-large': mode === true,
            'height-small': mode === false,
          }"
        >
          <li
            class="list-group-item d-flex align-items-center"
            v-for="(point, index) in listPunkt"
            :key="index"
          >
            <input
              v-if="mode == false"
              class="form-check-input me-2"
              type="checkbox"
              value=""
              aria-label="..."
              @change="addcheckPoint(point)"
            />
            <div class="flex-grow-1">
              {{ point.properties?.dsc }}
            </div>
            <MyButton @click="changeCenter(point)">Перейти</MyButton>
          </li>
        </ul>
        <div class="modal-footer">
          <MyButton v-if="mode == false" @click="AppendViewPunkts"
            >Выбрать пункты({{
              checkedPoints.length > 0 ? checkedPoints.length : listPunkt.length
            }}
            шт.)</MyButton
          >
        </div>
      </div>
      <div class="modalmap col-12 col-md">
        <!-- <div v-if="isMapLoading" class="loading-container">
          <div class="spinner">Загрузка карты...</div>
        </div> -->

        <yandex-map
          v-model="map"
          :settings="{
            location: localLocation,
          }"
          height="100%"
          width="95%"
        >
          <yandex-map-default-scheme-layer :settings="{ theme: 'dark' }" />
          <yandex-map-default-features-layer />
          <yandex-map-default-marker
            v-if="selectedSearch"
            :settings="{
              coordinates: selectedSearch,
            }"
          />
          <yandex-map-clusterer
            v-model="clusterer"
            :grid-size="2 ** 6"
            zoom-on-cluster-click
          >
            <yandex-map-marker
              v-for="(marker, index) in selectedPoints"
              :key="index"
              :settings="{
                coordinates: marker.coordinates,
                onClick: () => (openMarker = marker.properties?.id),
                zIndex: openMarker === marker.properties?.id ? 1 : 0,
              }"
            >
              <div class="marker"></div>
              <div
                v-if="openMarker === marker.properties?.id"
                class="popup"
                @click.stop="openMarker = null"
              >
                {{ marker.properties?.dsc }}
                <div>
                  <MyButton @click="selectPoint(marker.properties?.id)"
                    >Выбрать</MyButton
                  >
                </div>
              </div>
            </yandex-map-marker>
            <template #cluster="{ length }">
              <div
                class="leaflet-marker-icon marker-cluster leaflet-zoom-animated leaflet-clickable"
                :class="{
                  'marker-cluster-small': length < 100,
                  'marker-cluster-medium': length > 100 && length < 1000,
                  'marker-cluster-large': length > 1000,
                }"
              >
                <div>
                  <span>
                    {{ length }}
                  </span>
                </div>
              </div>
            </template>
          </yandex-map-clusterer>
        </yandex-map>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import {
  ComputedRef,
  computed,
  defineEmits,
  onMounted,
  ref,
  // watch,
  defineProps,
  shallowRef,
  Ref,
  watch,
  // reactive,
} from "vue";
import CloseButton from "@/components/UI/CloseButton.vue";
import { VueFinalModal } from "vue-final-modal";
import MyButton from "@/components/UI/MyButton.vue";
import {
  YandexMap,
  YandexMapDefaultFeaturesLayer,
  YandexMapDefaultSchemeLayer,
  YandexMapMarker,
  YandexMapClusterer,
  YandexMapDefaultMarker,
  // YandexMapControlButton,
  // YandexMapControls,
} from "vue-yandex-maps";
import type { YMapMarkerProps } from "@yandex/ymaps3-types/imperative/YMapMarker";
import type { YMap, LngLatBounds, LngLat } from "@yandex/ymaps3-types";
import type { YMapLocationRequest } from "@yandex/ymaps3-types/imperative/YMap";
import type { YMapClusterer } from "@yandex/ymaps3-types/packages/clusterer";
import type { SearchResponse } from "@yandex/ymaps3-types/imperative/search";
import { useStore } from "vuex";
import { IState } from "@/store/index";
import { sleep } from "@/hooks";

const store = useStore<IState>();
const props = defineProps<{
  // markers: Ref<Array<YMapMarkerProps>>;
  mode: Ref<boolean>;
}>();

const emit = defineEmits<{
  (e: "closeicon"): void;
  (event: "update", id: number | Array<number>): void;
}>();
// const markers = store.dispatch("fetchMarkers");
const mode = ref(props.mode);
const openMarker = ref<unknown | number>(null);
const checkedPoints = ref<Array<YMapMarkerProps>>([]);
const addcheckPoint = (fpoint: YMapMarkerProps) => {
  const index = checkedPoints.value.findIndex(
    (point: YMapMarkerProps) => point.properties?.id === fpoint.properties?.id
  );
  console.log(index);
  if (index > -1) {
    console.log(2333);
    checkedPoints.value.splice(index, 1);
  } else checkedPoints.value.push(fpoint);
};
// const handleClick = (event: MouseEvent) => console.log(event);
// console.log(pvzs.value);

//Можно использовать для различных преобразований
const map = shallowRef<YMap | null>(null);
const isMapLoading = ref(true); // Состояние загрузки карты
const center = ref<LngLat>([37.617644, 55.755819]);
function getCurrentPosition() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        center.value[1] = latitude;
        center.value[0] = longitude;
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
      },
      (error) => {
        console.error(`Error Code = ${error.code} - ${error.message}`);
      }
    );
  } else {
    console.error("Geolocation is not supported by this browser.");
  }
}
getCurrentPosition();
const localLocation = shallowRef<YMapLocationRequest>({
  center: center.value,
  zoom: 14,
  duration: 1000,
});

const selectedSearch = ref<LngLat | null>(null);
const search = ref("");
const searchResponse = shallowRef<null | SearchResponse>(null);
// const SearchPlace = async () => {
//   // eslint-disable-next-line no-undef
//   searchResponse.value = await ymaps3.search({
//     text: search.value,
//     bounds: map.value?.bounds,
//   });
// };
watch(search, async (val) => {
  if (!val) return;

  // Проверка, что уже координаты
  if (val.split(/[,.]/).length === 4) {
    selectedSearch.value = val.split(",").map((x) => parseFloat(x)) as LngLat;
    return;
  }

  await sleep(1);
  if (val !== search.value) return;

  // eslint-disable-next-line no-undef
  searchResponse.value = await ymaps3.search({
    text: val,
    bounds: map.value?.bounds,
  });
});

watch(selectedSearch, async () => {
  if (selectedSearch.value) {
    map.value?.setLocation({
      center: selectedSearch.value,
      zoom: 15,
      duration: 300,
    });
  }
});

const clusterer = shallowRef<YMapClusterer | undefined>(undefined);
const zoom = ref(15);
const bounds = ref<LngLatBounds>([
  [0, 0],
  [0, 0],
]);
const listPunkt: ComputedRef<Array<YMapMarkerProps>> = computed(() => {
  if (mode.value) {
    return selectedPoints.value.slice(0, 10);
  } else return selectedPoints.value;
});
const selectedPoints: ComputedRef<Array<YMapMarkerProps>> = computed(() => {
  const [minLon, minLat] = bounds.value[0];
  const [maxLon, maxLat] = bounds.value[1];

  return (
    store.state.points.markers?.filter(({ coordinates: [lon, lat] }) => {
      return lon > minLon && lon < maxLon && lat > maxLat && lat < minLat;
    }) ?? []
  );
});
// watch(map, (val) => console.log("map", val));

// watch(clusterer, (val) => console.log("cluster", val));

onMounted(() => {
  // store.dispatch("points/fetchMarkers");
  setInterval(() => {
    if (map.value) {
      if (zoom.value !== map.value.zoom && bounds.value !== map.value.bounds) {
        zoom.value = map.value.zoom;
        bounds.value = map.value.bounds;
      }
    }
  }, 2000);
});
// Обработка загрузки карты
const changeCenter = (point: YMapMarkerProps) => {
  localLocation.value = {
    center: point.coordinates,
    zoom: 17,
    duration: 500,
  };
  if (point.properties) {
    openMarker.value = point.properties.id;
  }
};
function selectPoint(id: number | unknown) {
  const index = store.state.points.markers.findIndex(
    (point: YMapMarkerProps) => point.properties?.id === id
  );
  if (typeof index === "number") {
    emit("update", index);
    emit("closeicon");
  }
}
const AppendViewPunkts = () => {
  let selectedPuncts: Array<number> = [];
  let proccessArray = [];
  if (checkedPoints.value.length > 0) {
    proccessArray = checkedPoints.value;
  } else {
    proccessArray = listPunkt.value;
  }
  proccessArray.forEach((point: YMapMarkerProps) => {
    const index = store.state.points.markers.findIndex(
      (p: YMapMarkerProps) => p.properties?.id === point.properties?.id
    );
    selectedPuncts.push(index as number);
  });
  if (proccessArray.length > 0) {
    emit("update", selectedPuncts);
  } else emit("closeicon");
};
</script>
<style scoped>
.inputs {
  display: flex;
  margin-bottom: 10px;
  align-items: center;

  label {
    display: flex;
    flex-direction: row;
    gap: 10px;

    input {
      border-radius: 5px;
      color: var(--vp-button-alt-text);
      background: var(--vp-button-alt-bg);
    }
  }
}

.form-input {
  width: 100%;
  padding: 0.2rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.search-option {
  padding: 10px;
  font-size: 1rem;
  background-color: white;
  color: black;
  border-bottom: 1px solid #ddd;
}

.search-option:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.datalist {
  position: absolute;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.datalist option {
  padding: 0.5rem;
  font-size: 1rem;
}

.datalist option:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.fixed-height-container {
  height: 400px;
  /* Фиксированная высота для списка и карты */
  /* overflow-y: auto; Скроллинг для списка */
}

@media (max-width: 768px) {
  .fixed-height-container {
    height: 300px;
    /* Уменьшенная высота для мобильных устройств */
  }
}

.form-check-input {
  margin-left: -0.25rem;
}

.list-group {
  /* margin-bottom: 10px; */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.height-large {
  max-height: 400px;
}

.height-small {
  max-height: 300px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
}

.modal-footer button {
  margin-left: 10px;
}

.popup {
  position: absolute;
  width: auto;
  height: auto;
  bottom: calc(100% + 10px);
  left: calc(100% - 60px);
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  color: black;
}

.marker-cluster-small {
  background-color: rgba(181, 226, 140, 0.6);
}

.marker-cluster-small div {
  background-color: rgba(110, 204, 57, 0.6);
}

.marker-cluster-medium {
  background-color: rgba(241, 211, 87, 0.6);
}

.marker-cluster-medium div {
  background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
  background-color: rgba(253, 156, 115, 0.6);
}

.marker-cluster-large div {
  background-color: rgba(241, 128, 23, 0.6);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
  background-color: rgb(181, 226, 140);
}

.leaflet-oldie .marker-cluster-small div {
  background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
  background-color: rgb(241, 211, 87);
}

.leaflet-oldie .marker-cluster-medium div {
  background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
  background-color: rgb(253, 156, 115);
}

.leaflet-oldie .marker-cluster-large div {
  background-color: rgb(241, 128, 23);
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 15px;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.marker-cluster span {
  line-height: 40px;
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
  -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
  -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
  -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
  /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
  -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out,
    -webkit-stroke-opacity 0.3s ease-in;
  -moz-transition: -moz-stroke-dashoffset 0.3s ease-out,
    -moz-stroke-opacity 0.3s ease-in;
  -o-transition: -o-stroke-dashoffset 0.3s ease-out,
    -o-stroke-opacity 0.3s ease-in;
  transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}

.marker {
  /* Set the marker size here */
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  /* Set the marker color here */
  background: #aa3300;

  display: inline-block;
  border-bottom-right-radius: 0;
  position: relative;
  transform: rotate(45deg);

  /* optional fanciness */
  border: 1px solid #881100;
}

/* inner circle (optional if you don't need the central dot) */
.marker::before {
  content: "";
  background: white;
  width: 50%;
  height: 50%;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  /* optional fanciness */
  box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
}

/* shadow (optional if you don't need a shadow) */
.marker::after {
  content: "";
  background: rgba(128, 128, 128, 0.2);
  width: 75%;
  height: 75%;
  border-radius: 100%;
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-50%, -50%) rotate(45deg) scaleX(0.5);
}
</style>
<style scoped>
@media (max-width: 768px) {
  .modalmap {
    height: 300px;
  }

  .list-group {
    max-height: 200px;
    overflow-y: auto;
  }
}
</style>
