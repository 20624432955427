<template>
  <LayuotPage>
    <div class="container-sm ps-7 p-0">
      <div class="row">
        <!-- Grid column -->
        <div class="col-md-12">
          <h2 class="pt-3 pb-1 text-left font-bold font-up deep-purple-text">
            Доставки
          </h2>
          <div class="row g-3 align-items-center mb-0">
            <div class="col-12 col-md-8 d-flex flex-column">
              <MyBreadCrumbs :crumbs="crumbs" class="mt-1"></MyBreadCrumbs>
            </div>
            <div class="col-12 col-md-4 text-md-end">
              <MyButton @click="VideoOpen" class="btn-success"
                >Видеоинструкция</MyButton
              >
            </div>
          </div>
        </div>
      </div>
      <!-- Блок фильтров и кнопки Excel -->
      <!-- Блок фильтров и кнопки Excel -->
      <div class="row g-3 align-items-center mb-1">
        <!-- Блок с фильтрами и ПВЗ -->
        <div class="col-12 col-md-8 d-flex flex-column flex-md-row">
          <!-- Dropdown для статуса -->
          <DropDownMenu
            :options="statusOptions"
            :defaultOption="{ key: 'active', value: 'Активные' }"
            dropdownId="dropdownMenuButton"
            @selectOption="onStatusChange"
            class="me-md-3 mb-1 mb-md-0"
          />

          <!-- Dropdown для выбора ПВЗ -->
          <DropDownMenu
            :options="filteredPvzOptions"
            :defaultOption="{ key: 'any', value: 'ПВЗ: Любой' }"
            dropdownId="dropdownPvzButton"
            @selectOption="onPvzChange"
            class="me-md-3 mb-1 mb-md-0"
          />
          <MyButton
            @click="RefreshDelivery"
            class="me-md-3 mb-1 mb-md-0 ml-3 w-md-auto w-sm-10"
            >Обновить</MyButton
          >
        </div>
        <!-- 
        <div class="col-12 col-md-8">
        </div> -->

        <!-- Кнопка скачивания Excel справа -->
        <div class="col-12 col-md-4 text-md-end">
          <button
            class="btn btn-outline-primary w-50 w-md-auto"
            @click="downloadExcel"
          >
            Скачать Excel
          </button>
        </div>
      </div>
      <div class="row" v-if="!isLoading">
        <PurchaseList
          :carts="filteredCarts"
          :manage="manage"
          @open-status="ShowModal"
          @open-qr="getQR"
        ></PurchaseList>
      </div>
      <div class="row" v-else>
        <AccordionSkeleton></AccordionSkeleton>
      </div>
    </div>
    <ModalsContainer />
  </LayuotPage>
</template>
<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import PurchaseList from "./components/PurchaseList.vue";
import LayuotPage from "../LayuotPage.vue";
import MyBreadCrumbs from "@/components/MyBreadCrumbs.vue";
import DropDownMenu from "@/components/UI/DropDownMenu.vue";
import RepaymentService from "./hooks/RepaymentService";
import StatusModal from "./modals/StatusModal.vue";
import AccordionSkeleton from "@/components/skeletons/AccordionSkeleton.vue";
import { ModalsContainer, useModal } from "vue-final-modal";
import { IRepaymentCart, TrackingStatus } from "./intefaces";
import QRModal from "./modals/QRModal.vue";
import VideoModal from "@/components/VideoModal.vue";
import MyButton from "@/components/UI/MyButton.vue";
import { notify } from "@kyvg/vue3-notification";
const crumbs = [
  {
    text: "Доставки",
    url: "/reviews",
  },
  {
    text: "Доставки",
  },
];

const manage = true;
const { isLoading, carts } = RepaymentService.getDelivery();

// Опции для фильтров по статусу
const statusOptions = [
  { key: "active", value: "Активные" },
  { key: "in_transit", value: "В пути" },
  { key: "ready_for_pickup", value: "Готовы к выдаче" },
  { key: "completed", value: "Завершенные" },
];

// ref для хранения опций ПВЗ
const pvzOptions = ref<Array<{ key: string; value: string }>>([
  { key: "any", value: "Любой" },
  { key: "dbs", value: "DBS" },
]);

const RefreshDelivery = async () => {
  const result = await RepaymentService.refreshdelivery();
  if (result == true) {
    notify({
      title: "Уведомление",
      text: "Обновление запущено",
    });
  }
};

// Отслеживаем изменения в carts и обновляем pvzOptions
watchEffect(() => {
  if (carts.value.length) {
    pvzOptions.value = Array.from(
      new Set(carts.value.map((cart) => cart.punkt?.id_wb))
    ).map((id_wb) => {
      const punkt = carts.value.find(
        (cart) => cart.punkt?.id_wb === id_wb
      )?.punkt;
      return {
        key: String(id_wb),
        value: punkt ? punkt.address : "Неизвестный ПВЗ",
      };
    });

    // Добавляем "Любой" как первый элемент
    pvzOptions.value.unshift({ key: "any", value: "Любой" });
  }
});

// Состояния фильтров
const selectedStatus = ref("active");
const selectedPvz = ref("any");

// Фильтрация по статусу
const filterByStatus = (
  carts: Array<IRepaymentCart>,
  statusKey: string
): Array<IRepaymentCart> => {
  switch (statusKey) {
    case "active":
      return carts.filter((cart) => cart.status !== "RECIEVE");
    case "in_transit":
      return carts.filter((cart) => cart.status === "DELIVERY");
    case "ready_for_pickup":
      return carts.filter(
        (cart) => cart.status === "DELIVERED" && cart.qrcode !== undefined
      );
    case "completed":
      return carts.filter((cart) => cart.status === "RECIEVE");
    default:
      return carts;
  }
};

// Фильтрация по ПВЗ
const filterByPvz = (
  carts: Array<IRepaymentCart>,
  pvzKey: string
): Array<IRepaymentCart> => {
  if (pvzKey === "any") {
    return carts;
  } else if (pvzKey === "dbs") {
    return carts.filter((cart) => !cart.punkt);
  } else {
    return carts.filter((cart) => String(cart.punkt?.id_wb) === pvzKey);
  }
};

// Применяем обе фильтрации
const filteredCarts = computed(() => {
  return filterByPvz(
    filterByStatus(carts.value, selectedStatus.value),
    selectedPvz.value
  );
});

// Динамическое обновление опций ПВЗ на основе выбранного статуса
const filteredPvzOptions = computed(() => {
  const filteredCartsByStatus = filterByStatus(
    carts.value,
    selectedStatus.value
  );

  const pvzSet = Array.from(
    new Set(filteredCartsByStatus.map((cart) => cart.punkt?.id_wb))
  ).map((id_wb) => {
    const punkt = filteredCartsByStatus.find(
      (cart) => cart.punkt?.id_wb === id_wb
    )?.punkt;
    return {
      key: String(id_wb),
      value: punkt ? punkt.address : "Неизвестный ПВЗ",
    };
  });

  // Добавляем "Любой" как первый элемент
  return [
    { key: "any", value: "Любой" },
    { key: "dbs", value: "DBS" },
    ...pvzSet,
  ];
});

// Логика выбора статуса
const onStatusChange = (statusKey: string) => {
  selectedStatus.value = statusKey;
  selectedPvz.value = "any"; // Сбрасываем выбранный ПВЗ при смене статуса
};

// Логика выбора ПВЗ
const onPvzChange = (pvzKey: string) => {
  selectedPvz.value = pvzKey;
};

const downloadExcel = async () => {
  await RepaymentService.exportDelivery();
};
const currentItemStatus = ref<Array<TrackingStatus> | undefined>();
const ShowModal = async (id: number) => {
  const items = (await RepaymentService.getItemStatus(id)).items;
  if (items) {
    currentItemStatus.value = items.value;
    open();
  }
};
const cartQr = ref("");
const getQR = async (cart: IRepaymentCart) => {
  const newCartQr = await RepaymentService.getQr(cart.id);
  if (newCartQr.value) {
    cartQr.value = newCartQr.value.qr_url;
    cart.qrcode = newCartQr.value;
    openQR();
  }
};
const { open, close } = useModal({
  component: StatusModal,
  attrs: {
    items: currentItemStatus,
    onCloseicon() {
      close();
    },
  },
});
const { open: openQR, close: closeQR } = useModal({
  component: QRModal,
  attrs: {
    qrcode: cartQr,
    onCloseicon() {
      closeQR();
    },
  },
});
const { open: VideoOpen, close: VideoClose } = useModal({
  component: VideoModal,
  attrs: {
    videotoken: "5efb25761fda91dd489c3fbee09935ff",
    onCloseicon() {
      VideoClose();
    },
  },
});
</script>
